import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import usePageTitle from '../../functions/global/usePageTitle'
import {KTCard} from '../../../_metronic/helpers'
import {Empty, Button, Pagination, Badge, Popconfirm, message} from 'antd'
import {showSupplier} from '../../functions/global/api'
import apiProvider from '../../functions/global/apiProvider'
import {Modal} from 'react-bootstrap'

interface Product {
  id: number
  category_id: number
  product_name: string
  stock: number
  discount: number
}

interface PurchaseDetail {
  id: number
  purchase_id: number
  product_id: number
  quantity: number
  price: number
  subtotal: number
  product: Product
}

interface Supplier {
  id: number
  name: string
  phone: string
}

interface Purchase {
  id: number
  supplier_id: number
  invoice_number: string
  invoice_date: string
  due_date: string | null
  payment_status: string
  subtotal: number
  ppn: string
  grand_total: number
  supplier: Supplier
  purchase_details: PurchaseDetail[]
}

interface PurchaseDataDetail {
  id: number
  supplier_id: number
  invoice_number: string
  invoice_date: string
  due_date: string | null
  payment_status: string
  product_id: number
  quantity: number
  price: number
  subtotal: number
  ppn: string
  grand_total: number
  created_at: string
  updated_at: string
  supplier: {
    id: number
    name: string
    phone: string
  }
  purchase_details: {
    code: any
    id: number
    purchase_id: number
    product_id: number
    quantity: number
    price: number
    subtotal: number
    product: {
      id: number
      category_id: number
      product_name: string
      stock: number
      discount: number
    }
  }[]
  code: {
    id: number
    code: string
    note: string
  }[]
}

const DaftarPembelianPage = () => {
  usePageTitle('Daftar Pembelian')
  const token = localStorage.getItem('token') || ''
  const [loading, setLoading] = useState(false)
  const [supId, setSupplierId] = useState('')
  const [paymentStatus, setPaymentStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [categoryData, setPurchaseData] = useState<Purchase[]>([])
  const [zeroData, setZeroData] = useState<string | undefined>('')
  const [supplier, setSupplier] = useState<Supplier[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [showAkunConfirmation, setShowAkunConfirmation] = useState(false)
  const [purchaseData, setPurchaseDataDetail] = useState<PurchaseDataDetail>()

  console.log(purchaseData, 'dataa')

  useEffect(() => {
    if (token) {
      getPurchase(1)
    }
  }, [token])

  useEffect(() => {
    showSupplier()
      .then((data) => {
        setSupplier(data || [])
      })
      .catch((error) => {
        console.error('Error fetching suppliers:', error)
        setSupplier([])
      })
  }, [])

  const getPurchase = async (page: number) => {
    setLoading(true)
    try {
      const res = await apiProvider.getPurchase(
        token,
        page,
        supId,
        paymentStatus,
        startDate,
        endDate
      )
      if (res.status === 200 && res.data && res.data.data) {
        const {data} = res.data.data
        setPurchaseData(data || [])
        setCurrentPage(res.data.data.current_page)
        setTotalItems(res.data.data.total)
        setPageSize(res.data.data.per_page)
        setZeroData('')
      } else {
        setPurchaseData([])
        setZeroData(res.data?.message || 'No data available')
      }
    } catch (error) {
      console.error('Error fetching purchase data:', error)
      setPurchaseData([])
      setZeroData('Error fetching data')
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = (field: string, value: string) => {
    switch (field) {
      case 'supplier':
        setSupplierId(value)
        break
      case 'paymentStatus':
        setPaymentStatus(value)
        break
      case 'startDate':
        setStartDate(value)
        break
      case 'endDate':
        setEndDate(value)
        break
    }
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
    getPurchase(page)
  }

  const resetFilter = () => {
    setSupplierId('')
    setPaymentStatus('')
    setStartDate('')
    setEndDate('')
    setCurrentPage(1)
    getPurchase(1)
  }

  const convertIDR = (value: string) => {
    return Number(value).toLocaleString('id-ID', {
      style: 'currency',
      currency: 'IDR',
      maximumSignificantDigits: 9,
    })
  }

  const handleDeleteSupplier = async (id: number) => {
    if (!token) {
      console.error('Token is not available')
      return
    }

    setLoading(true)
    try {
      const res = await apiProvider.DeletePurchase(token, id)
      if (res.status === 200) {
        message.success(res.data.message)
        getPurchase(currentPage)
      } else {
        message.error('Failed to delete supplier')
      }
    } catch (error) {
      console.error('Error deleting supplier:', error)
      message.error('Failed to delete supplier')
    } finally {
      setLoading(false)
    }
  }

  const isFilterActive = () => {
    return supId !== '' || paymentStatus !== '' || startDate !== '' || endDate !== ''
  }

  const loadPurchaseDetail = async (purchaseId: number) => {
    try {
      const response = await apiProvider.getDetailPurchase(token, purchaseId)
      if (response.status === 200) {
        setPurchaseDataDetail(response.data.data)
        console.log(response.data.data, 'detail all')

        setShowAkunConfirmation(true) // Menampilkan modal setelah data dimuat
      } else {
        console.error('Failed to fetch purchase details:', response.message)
      }
    } catch (error) {
      console.error('Error fetching purchase details:', error)
    }
  }

  console.log(purchaseData, 'data detail')

  return (
    <>
      <KTCard>
        <div className='card card-flush pt-3 mb-6 mb-lg-6'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Filter</h3>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row fw-bold'>
              <div className='col-3'>
                <label className='mb-2'>Supplier</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={supId}
                  onChange={(e) => handleFilter('supplier', e.target.value)}
                >
                  <option value=''>Pilih Supplier</option>
                  {supplier.map((supplier) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-3'>
                <label className='mb-2'>Status Pembayaran</label>
                <select
                  className='form-select'
                  name='statusPembayaran'
                  value={paymentStatus}
                  onChange={(e) => handleFilter('paymentStatus', e.target.value)}
                >
                  <option value=''>Pilih Status</option>
                  <option value='Lunas'>Lunas</option>
                  <option value='Tempo'>Tempo</option>
                </select>
              </div>
              <div className='col-3'>
                <label className='mb-2'>Dari</label>
                <input
                  type='date'
                  placeholder='dd/mm/yyyy'
                  className='form-control'
                  name='fromDate'
                  value={startDate}
                  onChange={(e) => handleFilter('startDate', e.target.value)}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Sampai</label>
                <input
                  type='date'
                  placeholder='dd/mm/yyyy'
                  className='form-control'
                  name='toDate'
                  value={endDate}
                  onChange={(e) => handleFilter('endDate', e.target.value)}
                />
              </div>
            </div>
            {isFilterActive() && (
              <div className='row mt-4'>
                <div className='col'>
                  <Button onClick={resetFilter}>Reset Filter</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </KTCard>
      <KTCard className='mt-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Semua Pembelian</h3>
          </div>
        </div>
        <div className='separator separator mb-4'></div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center'>
                  <th className='ps-4 rounded-start'>Tanggal</th>
                  <th>Nomor Nota / Faktur</th>
                  <th>Supplier</th>
                  <th>Status Pembayaran</th>
                  <th>Grand Total</th>
                  <th>Pembayaran Jatuh Tempo</th>
                  <th className='pe-4 rounded-end'>Detail</th>
                </tr>
              </thead>
              <tbody>
                {categoryData.length > 0 ? (
                  categoryData.map((item) => (
                    <tr key={item.id} className='text-center'>
                      <td className='ps-4'>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {item.invoice_date}
                        </div>
                      </td>

                      <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {item.invoice_number}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {item.supplier ? item.supplier.name : 'N/A'}
                        </div>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            item.payment_status === 'Lunas'
                              ? 'badge-light-success'
                              : 'badge-light-warning'
                          }`}
                        >
                          {item.payment_status}
                        </span>
                      </td>

                      <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {convertIDR(item.grand_total.toString())}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {item.due_date ? item.due_date : '-'}
                        </div>
                      </td>
                      <td>
                        <button
                          className='btn btn-primary btn-sm me-3'
                          onClick={() => {
                            setShowAkunConfirmation(true)
                            loadPurchaseDetail(item.id)
                          }}
                        >
                          Detail
                        </button>

                        <Popconfirm
                          title={`Apakah Anda yakin untuk menghapus ${item.invoice_number} ini?`}
                          onConfirm={() => handleDeleteSupplier(item.id)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <button className='btn btn-danger btn-sm'>Hapus</button>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>
                      <Empty description={zeroData ? zeroData : 'No Data'} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className='card-footer d-flex justify-content-between align-items-center'>
              <div>
                {totalItems > 0 && (
                  <span className='text-bold'>
                    {`${(currentPage - 1) * pageSize + 1}-${Math.min(
                      currentPage * pageSize,
                      totalItems
                    )} dari ${totalItems} Data pembelian`}
                  </span>
                )}
              </div>
              <Pagination
                current={currentPage}
                total={totalItems}
                pageSize={pageSize}
                onChange={handlePaginationChange}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
        <Modal show={showAkunConfirmation} onHide={() => setShowAkunConfirmation(false)} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Detail Pembelian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {purchaseData && (
              <form>
                <div className='mb-3'>
                  <label className='col-form-label'>Nomor Invoice:</label>
                  <input
                    type='text'
                    className='form-control'
                    value={purchaseData.invoice_number}
                    readOnly
                  />
                </div>
                <div className='mb-3'>
                  <label className='col-form-label'>Tanggal Invoice:</label>
                  <input
                    type='text'
                    className='form-control'
                    value={purchaseData.invoice_date}
                    readOnly
                  />
                </div>
                <div className='mb-3'>
                  <label className='col-form-label'>Status Pembayaran:</label>
                  <input
                    type='text'
                    className='form-control'
                    value={purchaseData.payment_status}
                    readOnly
                  />
                </div>
                {purchaseData.due_date && (
                  <div className='mb-3'>
                    <label className='col-form-label'>Tanggal Jatuh Tempo:</label>
                    <input
                      type='text'
                      className='form-control'
                      value={purchaseData.due_date}
                      readOnly
                    />
                  </div>
                )}

                <div className='mb-3'>
                  <label className='col-form-label'>Nama Supplier:</label>
                  <input
                    type='text'
                    className='form-control'
                    value={purchaseData.supplier.name}
                    readOnly
                  />
                </div>
                <div className='mb-3'>
                  <label className='col-form-label'>Telepon Supplier:</label>
                  <input
                    type='text'
                    className='form-control'
                    value={purchaseData.supplier.phone}
                    readOnly
                  />
                </div>

                <div className='mb-4' style={{overflowX: 'auto'}}>
                  <label className='col-form-label'>Detail Produk:</label>

                  {purchaseData.purchase_details.map((detail, index) => (
                    <div className='d-flex flex-row mb-4' key={index}>
                      <div className='col-md-3 me-4'>
                        <label className='col-form-label'>Nama Produk:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={`${detail.product.product_name}-${detail.code.note}`}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className='col-md-3 me-4'>
                        <label className='col-form-label'>Kode Produk:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={detail.code.code}
                          readOnly
                          disabled
                        />
                      </div>

                      <div className='col-md-1 me-4'>
                        <label className='col-form-label'>Harga:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={detail.price}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className='col-md-1 me-4'>
                        <label className='col-form-label'>Jumlah:</label>
                        <input
                          type='number'
                          className='form-control'
                          value={detail.quantity}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className='col-md-1 me-4'>
                        <label className='col-form-label'>Subtotal:</label>
                        <input
                          type='number'
                          className='form-control'
                          value={detail.subtotal}
                          readOnly
                          disabled
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className='mb-3'>
                  <label className='col-form-label'>PPN:</label>
                  <input type='text' className='form-control' value={purchaseData.ppn} readOnly />
                </div>
                <div className='mb-3'>
                  <label className='col-form-label'>Grand Total:</label>
                  <input
                    type='text'
                    className='form-control'
                    value={purchaseData.grand_total}
                    readOnly
                  />
                </div>
              </form>
            )}
          </Modal.Body>
        </Modal>
      </KTCard>
    </>
  )
}

export default DaftarPembelianPage
