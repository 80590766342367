import React, {ChangeEvent, useEffect, useState} from 'react'
import usePageTitle from '../../functions/global/usePageTitle'
import {Modal} from 'react-bootstrap'
import {KTCard} from '../../../_metronic/helpers'
import {
  addCategory,
  addNewProduct,
  deleteProduct,
  fetchCategories,
  showProducts,
  showSupplier,
  showUnit,
  updateProduct,
} from '../../functions/global/api'
import axios from 'axios'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {convertDate, convertIDR, formatCurrency, getDaysRemaining} from '../../functions/global'
import * as XLSX from 'xlsx'
import {useNavigate} from 'react-router-dom'
import {Button, Form, Input, message, Space} from 'antd'

const API_URL = process.env.REACT_APP_API_URL

interface Category {
  id: number
  category_name: string
}
interface Supplier {
  id: number
  name: string
}

const ProductPage = () => {
  usePageTitle('Semua Produk')

  const navigate = useNavigate()
  const [productData, setProductData] = useState<any[]>([])
  const [category, setCategory] = useState<any[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [supplier, setSupplier] = useState<Supplier[]>([])
  const [unit, setUnit] = useState<any[]>([])
  const [showEditConfirmation, setShowEditConfirmation] = useState(false)
  const [productToEdit, setProductToEdit] = useState<number | null>(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [productToDelete, setProductToDelete] = useState<number | null>(null)
  const [showProdukConfirmation, setShowProdukConfirmation] = useState(false)
  const [produkToProduk, setProdukToProduk] = useState<number | null>(null)
  const [visible, setVisible] = useState(true)
  const [form] = Form.useForm()
  const [newProduct, setNewProduct] = useState({
    product_name: '',
    stock: 0,
    discount: 0,
    supplier_id: '1',
    hpp_price: 0,
    faktur_number: 0,
    expired_date: '',
    expired_notif_date: '',
    category_name: '',
    category_code: '',
    units: [],
    codes: [],
  })

  const handleShowModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields() // Optional: Reset form fields when modal is closed
  }

  const handleCreate = async (values: any) => {
    console.log('Received values of form:', values)
    try {
      const response = await addNewProduct(values)

      console.log('Response:', response)
      if (response.status === 'success') {
        console.log('Product created successfully')
        showData()
        setProductData([...productData, response.data])
        message.success('Data telah dibuat')
        setNewProduct({
          product_name: '',
          stock: 0,
          discount: 0,
          supplier_id: '1',
          hpp_price: 0,
          faktur_number: 0,
          expired_date: '',
          expired_notif_date: '',
          category_name: '',
          category_code: '',
          units: [],
          codes: [],
        })
        setVisible(false) // Hide modal after successful creation
      } else {
        console.error('Gagal menambahkan produk, respons:', response)
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat menambahkan produk:', error)
    }
  }

  const showData = () => {
    showProducts().then((data) => {
      setProductData(data)
    })
  }

  useEffect(() => {
    fetchCategories().then((dataKategori) => {
      setCategories(dataKategori)
    })

    showSupplier().then((data) => {
      setSupplier(data)
    })

    showData()
  }, [])

  return (
    <>
      <div>
        <Button type='primary' onClick={handleShowModal}>
          Tambah Produk
        </Button>
        <Modal
          visible={visible}
          title='Tambah Produk'
          okText='Tambah'
          cancelText='Batal'
          onCancel={handleCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields()
                handleCreate(values)
              })
              .catch((info) => {
                console.log('Validasi gagal:', info)
              })
          }}
        >
          <Form form={form} layout='vertical'>
            <Form.Item
              name='product_name'
              label='Nama Produk'
              rules={[{required: true, message: 'Nama produk harus diisi'}]}
            >
              <Input />
            </Form.Item>
            <Form.Item name='stock' label='Stok'>
              <Input type='number' />
            </Form.Item>
            <Form.Item name='discount' label='Diskon'>
              <Input type='number' />
            </Form.Item>
            <Form.Item name='supplier_id' label='ID Supplier'>
              <Input />
            </Form.Item>
            <Form.Item name='hpp_price' label='Harga HPP'>
              <Input type='number' />
            </Form.Item>
            <Form.Item name='faktur_number' label='Nomor Faktur'>
              <Input type='number' />
            </Form.Item>
            <Form.Item name='expired_date' label='Tanggal Kadaluarsa'>
              <Input type='date' />
            </Form.Item>
            <Form.Item name='expired_notif_date' label='Tanggal Notifikasi Kadaluarsa'>
              <Input type='date' />
            </Form.Item>
            <Form.Item name='category_name' label='Nama Kategori'>
              <Input />
            </Form.Item>
            <Form.Item name='category_code' label='Kode Kategori'>
              <Input />
            </Form.Item>

            {/* Units */}
            <Form.List name='units'>
              {(fields, {add, remove}) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{display: 'flex', marginBottom: 8}}
                      align='baseline'
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, 'type']}
                        key={field.key} // Replace fieldKey with key
                        label={`Tipe Unit ${index + 1}`}
                        rules={[{required: true, message: 'Tipe unit harus diisi'}]}
                      >
                        <Input />
                      </Form.Item>
                      {/* Remaining Form.Item components */}
                    </Space>
                  ))}
                  {/* Add button remains the same */}
                </>
              )}
            </Form.List>

            {/* Codes */}
            <Form.List name='codes'>
              {(fields, {add, remove}) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key} // Use key instead of fieldKey
                      style={{display: 'flex', marginBottom: 8}}
                      align='baseline'
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, 'code']}
                        key={`${field.key}-code`} // Unique key for each Form.Item
                        label={`Kode ${index + 1}`}
                        rules={[{required: true, message: 'Kode harus diisi'}]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'note']}
                        key={`${field.key}-note`} // Unique key for each Form.Item
                        label='Catatan'
                        rules={[{required: true, message: 'Catatan harus diisi'}]}
                      >
                        <Input />
                      </Form.Item>
                      <Button type='link' onClick={() => remove(field.name)}>
                        Hapus
                      </Button>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type='dashed' onClick={() => add()} block>
                      Tambah Kode
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default ProductPage
