import usePageTitle from '../../functions/global/usePageTitle'
import {Button, Modal} from 'react-bootstrap'
import {KTCard, KTSVG} from '../../../_metronic/helpers'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import {Empty, message, Pagination} from 'antd'
import apiProvider from '../../functions/global/apiProvider'
import {useEffect, useState} from 'react'
import axios from 'axios'
import {fetchCategories} from '../../functions/global/api'

interface Category {
  id: number
  category_name: string
  code: string
}

interface Code {
  id: number
  code: string
  note: string
}

interface Unit {
  id: number
  type: string
  name: string
  conversion: number
  price: number
  multi_qty_1: number
  multi_price_1: number
  multi_qty_2: number
  multi_price_2: number
  multi_qty_3: number
  multi_price_3: number
  multi_qty_4: number
  multi_price_4: number
}

interface Product {
  product_id: number
  product_name: string
  category: Category
  code: Code
  unit: Unit
  stock: number // Add this field to Product if it is part of the data
}

interface AddProduct {
  category_id: string
  product_name: string
  codes: {
    code: string
    note: string
  }[]
}

const API_URL = process.env.REACT_APP_API_URL

const HargaProdukPage = () => {
  usePageTitle('Semua Produk')
  const token = localStorage.getItem('token') || ''

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [productData, setProductData] = useState<Product[]>([])
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [zeroData, setZeroData] = useState<string | undefined>('')
  const [categories, setCategories] = useState<Category[]>([])

  const getProductData = async (page: number) => {
    setLoading(true)
    try {
      const res = await apiProvider.getPriceProduk(token, page, '', '', '', '', '')
      if (res.status === 200 && res.data && res.data.data) {
        const {data} = res.data.data
        setProductData(data || [])
        setCurrentPage(res.data.data.current_page)
        setTotalItems(res.data.data.total)
        setPageSize(res.data.data.per_page)
        setZeroData('')
      } else {
        setProductData([])
        setZeroData(res.data?.message || 'No data available')
      }
    } catch (error) {
      console.error('Error fetching product data:', error)
      setProductData([])
      setZeroData('Error fetching data')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getProductData(currentPage)
  }, [currentPage])

  useEffect(() => {
    fetchCategories().then((dataKategori) => {
      setCategories(dataKategori)
    })
    handleSearch(1)
    // showData()
  }, [])

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const [newProduct, setNewProduct] = useState<AddProduct>({
    category_id: '',
    product_name: '',
    codes: [],
  })

  const [newCategory, setNewCategory] = useState({
    category_name: '',
    code: '',
  })
  const [arrangeBy, setArrangeBy] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [totalPages, setTotalPages] = useState<number>(1)

  const handleSearch = (eventOrPageNumber: React.MouseEvent<HTMLButtonElement> | number) => {
    if (typeof eventOrPageNumber === 'number') {
      const pageNumber = eventOrPageNumber

      const productCode = newProduct.codes.length > 0 ? newProduct.codes[0].code : ''

      const searchParams = {
        product_name: newProduct.product_name,
        category_id: newProduct.category_id,
        product_code: productCode,
        arrange_by: arrangeBy,
        sort_by: sortBy,
      }

      axios
        .get(
          `${API_URL}/product/price?page=&product_name=&category_id=&product_code=&arrange_by=&sort_by=`,
          {
            params: {...searchParams, page: pageNumber},
          }
        )
        .then((response) => {
          setProductData(response.data.data.data)
          setCurrentPage(pageNumber)
          setTotalPages(response.data.data.last_page)
        })
        .catch((error) => {
          console.error('Error searching products:', error)
        })
    } else {
    }
  }

  const formatRupiah = (amount: number) => {
    if (amount == null) return 'N/A'
    return `Rp ${new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // Remove decimal places
      maximumFractionDigits: 0, // Remove decimal places
    })
      .format(amount)
      .replace('Rp', '')}`
  }

  return (
    <>
      <KTCard>
        <div className='card card-flush pt-3 mb-6 mb-lg-6'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Filter</h3>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row fw-bold'>
              <div className='col-3'>
                <label className='mb-2'>Nama / Kode Produk</label>
                <input
                  placeholder='Masukkan nama / kode produk'
                  className='form-control'
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch(1)
                    }
                  }}
                  type='text'
                  name='search'
                  onChange={(e) => setNewProduct({...newProduct, product_name: e.target.value})}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Kategori</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={newProduct.category_id}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      handleSearch(1)
                    }
                  }}
                  onChange={(e) => setNewProduct({...newProduct, category_id: e.target.value})}
                >
                  <option value=''>Pilih Kategori</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-3'>
                <label className='mb-2'>Urutkan Berdasarkan</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={arrangeBy}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      handleSearch(1)
                    }
                  }}
                  onChange={(e) => setArrangeBy(e.target.value)}
                  required
                >
                  <option value='category_id'>Kategori</option>
                  <option value='product_name'>Nama Produk</option>
                </select>
              </div>
              <div className='col-3'>
                <label className='mb-2'>Urutan</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={sortBy}
                  onChange={(e) => {
                    if (arrangeBy) {
                      setSortBy(e.target.value)
                    } else {
                      message.error(
                        'Tolong isikan "Urutan" dahulu sebelum memilih "Urutkan Berdasarkan".'
                      )
                    }
                  }}
                  required
                >
                  <option value='asc'>A-Z</option>
                  <option value='desc'>Z-A</option>
                </select>
              </div>
              <div className='col-3 mt-6'>
                <Button className='btn btn-sm' onClick={() => handleSearch(1)}>
                  Cari
                </Button>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
      <KTCard className='mt-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Harga Produk</h3>
          </div>
        </div>
        <div className='separator separator'></div>
        {/* Tabel */}
        <div className='card-body'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center'>
                  <th className='ps-4 rounded-start'>No</th>
                  <th>Id Produk</th>
                  <th>Nama Produk</th>
                  <th>Kategori</th>
                  <th>Unit</th>
                  <th>Harga</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={6} className='text-center text-muted'>
                      Loading...
                    </td>
                  </tr>
                ) : productData.length > 0 ? (
                  productData.map((item, index) => (
                    <tr key={index} className='text-center'>
                      <td align='center'>{(currentPage - 1) * pageSize + index + 1}</td>
                      <td>
                        <button type='button' className='btn btn-text'>
                          <a
                            onClick={() => navigate(`/product/${item.product_id}`)} // Adjust based on your routing setup
                            className='text-dark fw-bold text-hover-primary fs-6'
                          >
                            {item.product_id}
                          </a>
                        </button>
                      </td>
                      <td>
                        <button type='button' className='btn btn-text'>
                          <a
                            onClick={() => navigate(`/product/${item.product_id}`)} // Adjust based on your routing setup
                            className='text-dark fw-bold text-hover-primary fs-6'
                          >
                            {item.product_name}
                          </a>
                        </button>
                      </td>

                      <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {item.category ? item.category.category_name : 'N/A'}
                        </div>
                      </td>

                      <td>
                        {item.unit?.name && (
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {item.unit.name}
                          </div>
                        )}
                      </td>
                      <td>
                        {item.unit?.price ? (
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {formatRupiah(item.unit.price)}
                          </div>
                        ) : (
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>N/A</div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className='text-center text-muted'>
                      <Empty
                        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                        imageStyle={{height: 60}}
                      />
                      {zeroData}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Pagination */}
          <div className='row mb-6 mt-6'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <Pagination
                current={currentPage}
                total={totalPages * 10}
                onChange={(page) => handleSearch(page)}
                showSizeChanger={false}
                pageSize={10}
              />
            </div>
          </div>
        </div>
        <ToastContainer position='top-right' autoClose={2000} />
      </KTCard>
    </>
  )
}

export default HargaProdukPage
