import React, {ReactNode, useEffect, useRef, useState} from 'react'
import usePageTitle from '../../functions/global/usePageTitle'
import {KTCard} from '../../../_metronic/helpers'
import 'react-toastify/dist/ReactToastify.css'
import {Button, Empty, Form, Modal, message} from 'antd'
import {addSupplier, getSearchProduk, showSupplier} from '../../functions/global/api'
import Input from 'antd/es/input/Input'
import apiProvider from '../../functions/global/apiProvider'
import {toast} from 'react-toastify'

interface Supplier {
  id: number
  name: string
}

interface SearchProduk {
  note: string
  stok: any
  id: number
  name: string
  code: string
  code_id: any
  unitName: string
  price: number
  defaulprice: number
  jumlah: number
  product_id: string
  unit_id: string
  hargaatingkat: any
}

interface Product {
  id: number
  code: string
  code_id: any
  name: string
  note: string
  tanggal: string
  jumlah: string
  diskon: string
  diskonprice: String
  defaultunit: string
  price: string
  product_id: string
  defaulprice: number
  unit_id: string
  subtotal: string
  hargaatingkat: any
}

type supplier = {
  name: string
  phone: string
  products?: Product[]
}

interface SearchProduct {
  id: number
  category_id: number
  product_name: string
  stock: number
  discount: number
  category: {
    id: number
    category_name: string
    code: string
  }
  codes: {
    id: number
    code: string
    note: string
  }[]
  units: {
    id: number
    type: string
    name: string
    conversion: number
    price: number
    multi_qty_1: number
    multi_price_1: number
    multi_qty_2: number
    multi_price_2: number
    multi_qty_3: number
    multi_price_3: number
    multi_qty_4: number
    multi_price_4: number
  }[]
  stock_opname: {
    id: number
    product_id: number
    supplier_id: number
    amount: number
    hpp_price: number
    faktur_number: string
    faktur_date: string
    expired_date: string
    expired_notif_date: string
    note: string | null
    responsible: string | null
    exp_notif: boolean
    is_approved: boolean
  }
}

const TambahPembelianPage = () => {
  usePageTitle('Daftar Pembelian')
  const [supplier, setSupplier] = useState<Supplier[]>([])
  const [newPurchasing, setNewPuchasing] = useState({
    supplier_id: '',
    nomorReferensi: '',
    invoice_date: '',
    due_date: '',
    payment_status: '',
  })
  const [suppliers, setSuppliers] = useState<any[]>([])

  const [newSupplier, setNewSupplier] = useState<supplier>({
    name: '',
    phone: '',
  })
  const [showEditConfirmation, setShowEditConfirmation] = useState(false)
  const [validationError, setValidationError] = useState<string | null>(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [grandTotal, setGrandTotal] = useState<number>(0)
  const [subtotalAll, setSubtotalAll] = useState<number>(0)
  const [searchValue, setSearchValue] = useState('')
  const dropdownRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [filteredData, setFilteredData] = useState<SearchProduk[]>([])
  const [productQty, setProductQty] = useState<any>({})
  const [selectedProduct, setSelectedProduct] = useState<Product[]>([])
  const inputModalRef = useRef<HTMLInputElement | null>(null)
  const inputModalRef2 = useRef<HTMLInputElement | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [ppnValue, setPpnValue] = useState<number>(0)
  const token = localStorage.getItem('token')

  const handleNomorReferensiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPuchasing({
      ...newPurchasing,
      nomorReferensi: e.target.value,
    })
  }

  useEffect(() => {
    showSupplier().then((data) => {
      setSupplier(data)
    })
  }, [])

  const subTotalProduct = (jumlah: string, price: string, waktu: string = 'tidak') => {
    const subtotalValue =
      Number(jumlah) * Number(price) + (waktu === 'tidak' ? 0 : (Number(price) * Number()) / 100)
    return String(subtotalValue)
  }

  const handleSelectProduct = (product: any) => {
    if (!product || !product.hargaatingkat) {
      console.error('Product or hargaatingkat is undefined:', product)
      return
    }

    const existingProduct = selectedProduct.find(
      (item: any) => item.id === product.id && item.code === product.code
    )

    if (existingProduct) {
      const updatedProducts = selectedProduct.map((item: any) => {
        if (item.id === product.id && item.code === product.code) {
          const hargaunit = product.hargaatingkat.find(
            (unit: any) => unit.name === item.defaultunit
          )

          if (!hargaunit) {
            console.error('Hargaunit is undefined for product:', product)
            return item
          }

          const updatedQuantity = parseInt(item.jumlah) + (hargaunit.minimum || 1)

          const updatedProductQty = {
            ...productQty,
            [product.id + product.code]: updatedQuantity,
          }
          setProductQty(updatedProductQty)

          const updatedSubtotal = subTotalProduct(String(updatedQuantity), String(hargaunit.price))

          // Log code_id when updating an existing product
          console.log('Updating existing product with code_id:', product.code_id)

          // Set subtotal in the updated product
          return {
            ...item,
            jumlah: String(updatedQuantity),
            price: String(hargaunit.price),
            defaultunit: hargaunit.name,
            subtotal: updatedSubtotal,
            code_id: product.code_id,
          }
        }
        return item
      })

      setSelectedProduct(updatedProducts)
    } else {
      const hargaPcs = product.hargaatingkat[0]?.price || '0'
      const jumlahPcs = product.hargaatingkat[0]?.conversion || '1'

      const updatedProduct: any = {
        ...product,
        jumlah: String(jumlahPcs),
        price: '0', // Set price to 0 for new products
        defaultunit: product.hargaatingkat[0]?.name || '',
        defaultPrice: 1,
        subtotal: subTotalProduct(String(jumlahPcs), '0'), // Assuming subtotal calculation
        code_id: product.code_id, // Make sure code_id is included for new products
      }

      // Log code_id when adding a new product
      console.log('Adding new product with code_id:', product.code_id)

      setSelectedProduct([...selectedProduct, updatedProduct])
    }
    setSearchValue('')
  }

  const handleSearchProduct = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchValue(value)

    try {
      const response = await getSearchProduk(value)

      const data = response.data.data || []
      console.log(data, 'hasil search')

      const fillerData = data.flatMap(
        (item: {
          id: {toString: () => any}
          product_name: any
          codes: any[]
          stock: any
          units: any[]
        }) =>
          item.codes?.map((code: {code: any; note: any; id: any}) => ({
            id: item.id,
            name: item.product_name,
            code: code.code,
            code_id: code.id, // Ensure code_id is included here
            note: code.note,
            stok: item.stock,
            hargaatingkat: item.units.map(
              (unit: {
                id: any
                name: any
                conversion: any
                price: any
                multi_qty_1: any
                multi_price_1: any
                multi_qty_2: any
                multi_price_2: any
                multi_qty_3: any
                multi_price_3: any
                multi_qty_4: any
                multi_price_4: any
              }) => ({
                id: unit.id,
                name: unit.name,
                conversion: unit.conversion,
                price: unit.price,
                multi_qty_1: unit.multi_qty_1,
                multi_price_1: unit.multi_price_1,
                multi_qty_2: unit.multi_qty_2,
                multi_price_2: unit.multi_price_2,
                multi_qty_3: unit.multi_qty_3,
                multi_price_3: unit.multi_price_3,
                multi_qty_4: unit.multi_qty_4,
              })
            ),
            unitName: item.units?.map((unit: {name: any}) => unit.name).join(', ') || '',
            price: '0', // Set initial price to 0
            jumlah: item.units?.[0]?.conversion || 0, // Assuming this is the quantity to be ordered, initially 0
            product_id: item.id.toString(),
            unit_id: item.units?.[0]?.id.toString() || '', // Assuming id of the first unit
          })) || []
      )

      setFilteredData(fillerData)
    } catch (error) {
      console.error('Error fetching product data:', error)
      // Handle error if necessary
    }
  }

  console.log(filteredData, 'dsadas')

  useEffect(() => {
    if (showModal) {
      inputModalRef.current?.focus()
    } else {
      inputModalRef2.current?.focus()
    }
  }, [showModal])

  const handleProductQtyChange = (index: number, value: string) => {
    const updatedProducts = [...selectedProduct]
    updatedProducts[index].jumlah = value

    updatedProducts[index].subtotal = subTotalProduct(value, updatedProducts[index].price)

    setSelectedProduct(updatedProducts)
  }

  const handleProductPriceChange = (index: number, value: string) => {
    const updatedProducts = [...selectedProduct]
    updatedProducts[index].price = value

    updatedProducts[index].subtotal = subTotalProduct(updatedProducts[index].jumlah, value)

    setSelectedProduct(updatedProducts)
  }

  const convertIDR = (value: string) => {
    return Number(value).toLocaleString('id-ID', {
      style: 'currency',
      currency: 'IDR',
      maximumSignificantDigits: 9,
    })
  }

  const handlePpnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPpnValue(parseInt(e.target.value))
  }

  const handleRemoveItem = (productId: number) => {
    const updatedProducts = selectedProduct.filter((product) => product.id !== productId)
    setSelectedProduct(updatedProducts)
  }

  useEffect(() => {
    if (showEditConfirmation !== null) {
      const selectedSupplier = suppliers.find((supplier) => supplier.id)

      if (selectedSupplier) {
        setNewSupplier({
          name: selectedSupplier.name,
          phone: selectedSupplier.phone,
        })
      }
    }
  }, [showEditConfirmation, suppliers])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target
    setNewSupplier({
      ...newSupplier,
      [name]: value,
    })
  }

  const showData = () => {
    showSupplier()
      .then((data) => {
        setSupplier(data)
      })
      .catch((error) => {
        console.error('Error fetching suppliers:', error)
      })
  }

  const handleToggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const handleAddSupplier = () => {
    const {name, phone} = newSupplier
    if (!name || !phone) {
      setValidationError('Tolong isi semua kolom')
      return
    }
    setValidationError(null)
    addSupplier(name, phone)
      .then((response) => {
        setNewSupplier({
          name: '',
          phone: '',
        })
        setModalVisible(false)
        showData()
        message.success('Supplier berhasil ditambahkan')
      })
      .catch((error) => {
        console.error('Error adding supplier:', error)
      })
  }

  const handleAddPurchase = async () => {
    const formDataAPI = {
      supplier_id: parseInt(newPurchasing.supplier_id),
      invoice_number: newPurchasing.nomorReferensi,
      invoice_date: newPurchasing.invoice_date,
      payment_status: newPurchasing.payment_status,
      ...(newPurchasing.payment_status === 'Tempo' && {due_date: newPurchasing.due_date}),
      products: selectedProduct.map((product) => ({
        product_id: product.id,
        code_id: product.code_id,
        quantity: parseInt(product.jumlah),
        price: parseFloat(product.price),
        subtotal: parseFloat(product.subtotal),
      })),
      subtotal: subtotalAll,
      ppn: ppnValue,
      grand_total: grandTotal,
    }
    const formDataString = JSON.stringify(formDataAPI)

    console.log(formDataAPI, 'form dikirim')

    const res = await apiProvider.addPurchase(token, formDataString)

    if (res.status === 200) {
      console.log(res.data, 'berhasil')
      message.success('Pembelian berhasil ditambahkan')
      setNewPuchasing({
        supplier_id: '',
        nomorReferensi: '',
        invoice_date: '',
        payment_status: '',
        due_date: '',
      })
      setSelectedProduct([])
      setSubtotalAll(0)
      setPpnValue(0)
      setGrandTotal(0)
    } else {
      if (res.data.errors) {
        Object.keys(res.data.errors).forEach((key) => {
          res.data.errors[key].forEach((error: any) => {
            message.error(`${error}`)
          })
        })
      } else {
        message.error(res.data.message || 'Terjadi kesalahan saat menambahkan pembelian')
      }
    }
  }

  useEffect(() => {
    handleCalculateGrandTotal()
    calculateSubtotal()
  }, [selectedProduct, ppnValue])

  const calculateSubtotal = () => {
    const subtotal = selectedProduct.reduce(
      (total, product) => total + Number(product.jumlah) * Number(product.price),
      0
    )
    setSubtotalAll(subtotal)
    return subtotal
  }

  const handleCalculateGrandTotal = () => {
    const calculatedGrandTotal = selectedProduct.reduce(
      (total, product) =>
        total + Number(product.jumlah) * Number(product.price) * (1 + ppnValue / 100),
      0
    )

    setGrandTotal(calculatedGrandTotal)
  }

  return (
    <>
      <KTCard>
        <div className='card card-flush pt-3 mb-6 mb-lg-6'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_profile_details'
            aria-expanded='true'
            aria-controls='kt_account_profile_details'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Tambah Pembelian</h3>
            </div>
          </div>
          <div className='separator separator'></div>

          <div className='card-body pt-6'>
            <div className='row fw-bold'>
              <div className='col-3'>
                <label className='mb-2'>Nama Supplier:</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={newPurchasing.supplier_id}
                  onChange={(e) => {
                    if (e.target.value === 'tambah_supplier_baru') {
                      handleToggleModal()
                    } else {
                      setNewPuchasing({...newPurchasing, supplier_id: e.target.value})
                    }
                  }}
                >
                  <option value=''>Pilih Supplier</option>
                  <option value='tambah_supplier_baru'>Tambah Supplier Baru</option>
                  {supplier.map((supplier) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-3'>
                <label className='mb-2'>Nomor Nota / Faktur</label>
                <input
                  placeholder='Masukkan nota / faktur'
                  className='form-control'
                  type='text'
                  name='nomorReferensi'
                  value={newPurchasing.nomorReferensi}
                  onChange={handleNomorReferensiChange}
                />
              </div>

              <div className='col-3'>
                <label className='mb-2'>Tanggal Pembelian</label>
                <input
                  placeholder=''
                  className='form-control'
                  type='date'
                  name='tanggal'
                  value={newPurchasing.invoice_date}
                  onChange={(e) =>
                    setNewPuchasing({...newPurchasing, invoice_date: e.target.value})
                  }
                />
              </div>

              <div className='col-3'>
                <label className='mb-2'>Status Pembayaran</label>
                <select
                  className='form-select'
                  name='statusPembayaran'
                  value={newPurchasing.payment_status}
                  onChange={(e) =>
                    setNewPuchasing({...newPurchasing, payment_status: e.target.value})
                  }
                >
                  <option value=''>Pilih Status</option>
                  <option value='Lunas'>Lunas</option>
                  <option value='Tempo'>Tempo</option>
                </select>
              </div>
            </div>
            <div className='row fw-bold mt-4'>
              {newPurchasing.payment_status === 'Tempo' && (
                <div className='col-3'>
                  <label className='mb-2'>Tanggal Jatuh Tempo</label>
                  <input
                    placeholder='Masukkan tanggal jatuh tempo'
                    className='form-control'
                    type='date'
                    name='tanggal'
                    value={newPurchasing.due_date}
                    onChange={(e) => setNewPuchasing({...newPurchasing, due_date: e.target.value})}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </KTCard>
      <KTCard className='mt-10'>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex align-items-center position-relative'>
            <div>
              <div className='d-flex align-items-center my-1 col-3'>
                <i className='fas fa-search position-absolute ms-3'></i>
                <input
                  className='form-control w-200px ps-9'
                  onKeyDown={(e) => {
                    if (e.key == 'Tab') {
                      e.preventDefault()
                      setSearchValue('')
                      const firstInputInTable = tableRef.current?.querySelector('input')
                      if (firstInputInTable) {
                        firstInputInTable.focus()
                      } else {
                        dropdownRef.current?.focus()
                      }
                    } else if (e.key == 'ArrowUp') {
                      e.preventDefault()
                      setSelectedIndex((prevIndex) =>
                        prevIndex === null ? filteredData.length - 1 : Math.max(0, prevIndex - 1)
                      )
                    } else if (e.key == 'ArrowDown') {
                      e.preventDefault()
                      setSelectedIndex((prevIndex) =>
                        prevIndex === null ? 0 : Math.min(filteredData.length - 1, prevIndex + 1)
                      )
                    } else if (e.key == 'Enter') {
                      e.preventDefault()
                      console.log('log', selectedIndex)
                      if (filteredData.length === 1) {
                        handleSelectProduct(filteredData[0])
                        console.log('log', filteredData[0])
                      } else if (filteredData.length === 0) {
                        setSearchValue('')
                      } else {
                        handleSelectProduct(filteredData[selectedIndex])
                      }
                    } else if (e.key === 'Escape') {
                      e.preventDefault()
                      setSearchValue('')
                    }
                  }}
                  placeholder='Search'
                  onFocus={() => {
                    if (inputModalRef2.current) {
                      inputModalRef2.current.style.borderColor = 'blue'
                    }
                  }}
                  onBlur={() => {
                    if (inputModalRef2.current) {
                      inputModalRef2.current.style.borderColor = ''
                    }
                  }}
                  data-kt-user-table-filter='search'
                  value={searchValue}
                  ref={inputModalRef2}
                  onChange={(e) => handleSearchProduct(e)}
                ></input>
              </div>
            </div>
            {searchValue && (
              <div className='card-title'>
                <div
                  className='search-results position-absolute bg-white border rounded '
                  style={{marginTop: '-15px', width: 'auto'}}
                >
                  <ul className='list-group' style={{marginBottom: '10px'}}>
                    <div className='list-group-item d-flex justify-content-between align-items-center'>
                      <div
                        className='col ms-auto'
                        style={{flex: 1, minWidth: 100, fontWeight: 'bold'}}
                      >
                        Nama
                      </div>
                      <div
                        className='col ms-auto'
                        style={{flex: 1, minWidth: 150, fontWeight: 'bold'}}
                      >
                        Keterangan
                      </div>
                      <div
                        className='col ms-auto'
                        style={{flex: 1, minWidth: 100, fontWeight: 'bold'}}
                      >
                        Kode
                      </div>

                      <div
                        className='ms-auto'
                        style={{flex: 0.5, minWidth: 50, fontWeight: 'bold'}}
                      >
                        Stok
                      </div>
                    </div>
                    {filteredData.length > 0 ? (
                      filteredData.map((item, index) => (
                        <a
                          key={`${item.id}-${index}`}
                          style={{
                            cursor: 'pointer',
                            color: index === selectedIndex ? 'white' : 'black',
                            backgroundColor: index === selectedIndex ? '#34759a' : '',
                          }}
                          className='list-group-item d-flex justify-content-between align-items-center'
                          onClick={() => {
                            inputModalRef2.current?.focus()
                            handleSelectProduct(item)
                          }}
                        >
                          <div className='col ms-auto' style={{flex: 1, minWidth: 100}}>
                            {item.name}
                          </div>
                          <div className='col ms-auto' style={{flex: 1, minWidth: 150}}>
                            {item.note}
                          </div>
                          <div className='col ms-auto' style={{flex: 1, minWidth: 100}}>
                            {item.code}
                          </div>

                          <div className='ms-auto' style={{flex: 0.5, minWidth: 50}}>
                            {`${item.stok} `}
                          </div>
                        </a>
                      ))
                    ) : (
                      <h1 style={{textAlign: 'center', margin: '20px 0'}}>Belum Ada Data Produk</h1>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-muted fw-bolder fs-7 text-uppercase bg-light text-center'>
                  <th
                    className='ps-4 rounded-start'
                    style={{whiteSpace: 'nowrap', textAlign: 'center'}}
                  >
                    Nama Produk
                  </th>
                  <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>Kode</th>

                  <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>Kuantitas</th>
                  <th
                    className='pe-4 rounded-end'
                    style={{whiteSpace: 'nowrap', textAlign: 'center'}}
                  >
                    Harga
                  </th>
                  <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>Subtotal</th>
                  <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>Aksi</th>
                </tr>
              </thead>

              <tbody>
                {selectedProduct.length > 0 ? (
                  selectedProduct.map((product, index) => (
                    <tr key={product.id}>
                      <td className='text-center fw-bolder'>{product.name}</td>
                      <td className='text-center fw-bolder'>
                        {product.code}- {product.note}
                      </td>

                      <td className='text-center col-md-1'>
                        <input
                          type='number'
                          className='form-control '
                          value={product.jumlah}
                          onChange={(e) => handleProductQtyChange(index, e.target.value)}
                        />
                      </td>
                      <td className='text-center text-muted col-md-2'>
                        <input
                          type='number'
                          className='form-control form-control-md'
                          value={product.price}
                          onChange={(e) => handleProductPriceChange(index, e.target.value)}
                        />
                      </td>
                      <td className='text-center fw-bolder'>
                        <span>{convertIDR(product.subtotal)}</span>
                      </td>
                      <td className='text-center'>
                        <button
                          type='button'
                          data-repeater-delete=''
                          className='btn btn-sm btn-icon btn-light-danger'
                          onClick={() => handleRemoveItem(product.id)}
                        >
                          <i className='bi-duotone bi-x fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className='text-center text-muted'>
                      <Empty
                        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                        imageStyle={{height: 60}}
                      />
                    </td>
                  </tr>
                )}
                <tr className='border-0'>
                  <td colSpan={3}></td>
                  <td className='fw-bold fs-5' colSpan={1}>
                    Total Item:{' '}
                  </td>
                  <td>{selectedProduct.length}</td>
                </tr>

                <tr className='border-0'>
                  <td colSpan={3}></td>
                  <td colSpan={1}>
                    <h4>SubTotal</h4>
                  </td>
                  <td>
                    <h4>
                      <h1>{convertIDR(subtotalAll.toString())}</h1>
                    </h4>
                  </td>
                </tr>
                <tr className='border-0'>
                  <td colSpan={3}></td>
                  <td className='fw-bold fs-5' colSpan={1}>
                    PPN (%):
                  </td>
                  <td className='col-md-1 text-muted '>
                    <input
                      type='number'
                      className='form-control'
                      value={ppnValue}
                      onChange={handlePpnChange}
                    />
                  </td>
                </tr>
                <tr className='border-0'>
                  <td colSpan={3}></td>
                  <td colSpan={1}>
                    <h1>Grand Total</h1>
                  </td>
                  <td>
                    <h1>{convertIDR(grandTotal.toString())}</h1>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='separator separator mb-6'></div>
          <div className='d-flex justify-content-end mb-4'>
            <button
              id='kt_signin_submit'
              type='submit'
              className='btn btn-primary me-2 px-6'
              onClick={handleAddPurchase}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                Tambah Pembelian
              </span>
            </button>
          </div>
        </div>
      </KTCard>
      <Modal
        title='Tambah Supplier Baru'
        visible={modalVisible}
        onOk={handleAddSupplier}
        onCancel={handleToggleModal}
        footer={[
          <Button key='back' onClick={handleToggleModal}>
            Batal
          </Button>,
          <Button key='submit' type='primary' onClick={handleAddSupplier}>
            Simpan
          </Button>,
        ]}
      >
        <Form>
          <Form.Item
            label='Nama Supplier'
            validateStatus={validationError ? 'error' : ''}
            help={validationError}
          >
            <Input
              name='name'
              value={newSupplier.name}
              onChange={handleInputChange}
              placeholder='Masukkan nama supplier'
            />
          </Form.Item>
          <Form.Item label='Nomor Telepon'>
            <Input
              name='phone'
              type='number'
              value={newSupplier.phone}
              onChange={handleInputChange}
              placeholder='Masukkan nomor telepon'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default TambahPembelianPage
