// apiProvider.js

import axios from 'axios'

export const BASE_URL = 'https://gate.projotech.id/api/'

const API = async (
  url,
  options = {
    method: 'GET',
    body: {},
    head: {},
  }
) => {
  const request = {
    baseURL: BASE_URL,
    method: options.method,
    timeout: 10000,
    url,
    headers: options.head,
    responseType: 'json',
  }

  if (options.method === 'POST') request.data = options.body

  try {
    const res = await axios(request)
    return res
  } catch (error) {
    console.error('Error during API call:', error)
    throw error
  }
}

const apiProvider = {
  // Auth
  Login: async (params) => {
    return API('login', {
      method: 'POST',
      head: {
        'Content-Type': 'multipart/form-data',
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },
  SemuaDataKasir: async (token, page) => {
    return API(`cashier?page=${page}`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },

  // Data Kasir
  GetSearch: async (token, search) => {
    return API(`product?search=${search}`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },

  // Supplier
  GetAllDataSupplier: async (token, page, keyword) => {
    return API(`supplier?page=${page}&search=${keyword}`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },
  AddSupplier: async (token, params) => {
    return API('supplier', {
      method: 'POST',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },
  UpdateSupplier: async (token, id, params) => {
    return API(`supplier/${id}`, {
      method: 'POST',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },
  DeleteSupplier: async (token, id) => {
    return API(`supplier/${id}`, {
      method: 'DELETE',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },

  // Management Akun
  GetAccount: async (token, page, keyword) => {
    return API(`user?page=${page}&search=${keyword}`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },
  GetDetailAccount: async (token, id) => {
    return API(`user/${id}`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },
  AddAccount: async (token, params) => {
    return API('user', {
      method: 'POST',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },
  UpdateAccount: async (token, id, params) => {
    return API(`user/${id}`, {
      method: 'POST',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },
  UpdateAccount: async (token, id, params) => {
    return API(`user/${id}/status`, {
      method: 'POST',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },

  DeleteAccount: async (token, id) => {
    return API(`user/${id}`, {
      method: 'DELETE',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },

  // Pembelian
  getPurchase: async (token, page, sup_id, payment_status, start_date, end_date) => {
    return API(
      `purchase?page=${page}&supplier_id=${sup_id}&payment_status=${payment_status}&start_date=${start_date}&end_date=${end_date}`,
      {
        method: 'GET',
        head: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response
    })
  },
  getDetailPurchase: async (token, id) => {
    return API(`purchase/${id}`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },
  addPurchase: async (token, params) => {
    return API(`purchase`, {
      method: 'POST',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },
  DeletePurchase: async (token, id) => {
    return API(`purchase/${id}`, {
      method: 'DELETE',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },

  // Settings
  getSettings: async (token) => {
    return API(`setting`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },
  updateSettings: async (token, params) => {
    return API(`setting/update`, {
      method: 'POST',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },

  // Unit
  getUnit: async (token,page) => {
    return API(`unit?page=${page}`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },
  getUnitDetail: async (token, id) => {
    return API(`unit/${id}`, {
      method: 'GET',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },
  addUnit: async (params, token) => {
    console.log('test', params)
    console.log('test', token)
    return API(`unit`, {
      method: 'POST',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      console.log('error', err.response)
      return err.response
    })
  },
  updateUnit: async (token, id, params) => {
    return API(`unit/${id}`, {
      method: 'POST',
      head: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response
    })
  },
  deleteUnit: async (token, id) => {
    return API(`unit/${id}`, {
      method: 'DELETE',
      head: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response
    })
  },

  getPriceProduk: async (
    token,
    page,
    product_name,
    category_id,
    product_code,
    arrange_by,
    sort_by
  ) => {
    return API(
      `product/price?page=${page}&product_name=${product_name}&category_id=${category_id}&product_code=${product_code}&arrange_by=${arrange_by}&sort_by=${sort_by}`,
      {
        method: 'GET',
        head: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response
    })
  },
}

export default apiProvider
