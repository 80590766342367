import React, { ReactNode, useState } from 'react';
import { toast } from 'react-toastify';

interface Transaction {
  hour: ReactNode;
  id: number;
  date: string;
  total: number;
  details: {
    id: number;
    product_name: string;
    product_code: string;
    quantity_unit: string;
    price: number;
    quantity: number;
    product_id: string;
    unit_id: string;
  }[];
}

interface TahanTransaksiProps {
  showTahanTransaksi: boolean;
  onCloseTahanTransaksi: () => void;
  ongetData: (product: any) => void; // Pass the appropriate type for the argument
  datatransakksi: Transaction[];
  onDelte: (id: number) => void; // Correct spelling to 'onDelete'
  idtrans: any;
}

const TahanTransaksi: React.FC<TahanTransaksiProps> = ({ showTahanTransaksi, onCloseTahanTransaksi, ongetData, datatransakksi, onDelte, idtrans }) => {
  const handleDelete = (id: number) => {
    onDelte(id);
  };

  const handleGetData = (trans: Transaction, idtranx: number) => {
    const originalArray = trans.details;
    console.log('data', idtranx);

    idtrans(idtranx);
    ongetData(trans);
    onCloseTahanTransaksi();
  };

  return (
    <>
      {showTahanTransaksi && (
        <div className='modal-backdrop fade show'></div>
      )}
      {showTahanTransaksi && (
        <div className='modal fade show d-flex align-items-center justify-content-center' id='kt_modal_add_user' style={{ display: 'block' }}>
          <div className='modal-dialog modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Transaksi Terkini</h5>
                <button type='button' className='btn-close' onClick={onCloseTahanTransaksi}></button>
              </div>
              <div className='modal-body'>
                <table>
                  <tbody>
                    {datatransakksi.map((transaction, index) => (
                      <tr key={index}>
                        <td style={{ paddingRight: "10px" }}>
                          <button
                            className='btn btn-white btn-sm'
                            onClick={() => {
                              handleDelete(transaction.id);
                            }}
                          >
                            <i className='bi bi-x-lg'></i>
                          </button>
                        </td>
                        <td style={{ paddingRight: "50px" }}>{transaction.date} {transaction.hour}</td>
                        <td style={{ paddingRight: "10px", paddingTop: "10px" }}>
                          <a href="#" onClick={() => handleGetData(transaction, transaction.id)}>
                            <p>Lanjut</p>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <form id='kt_modal_add_user_form' className='form' noValidate>
                </form>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' onClick={onCloseTahanTransaksi}>
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TahanTransaksi;
