import React from 'react'
import DashboardPage from '../../modules/admintbk/DashboardPage'
import POSPage from '../../modules/admintbk/POSPage'
import SettingPage from '../../modules/admintbk/SettingPage'

const SettingNOtaa = () => {
  return <SettingPage />
}

export default SettingNOtaa
