import React, { useEffect, useState } from 'react';
import usePageTitle from '../../functions/global/usePageTitle';
import { Button, Modal } from 'react-bootstrap';
import { KTCard } from '../../../_metronic/helpers';
import { Empty, Pagination, Popconfirm, message } from 'antd';
import apiProvider from '../../functions/global/apiProvider';

interface UnitData {
    id: number;
    type: string;
    name: string;
}

interface UnitDetail {
    id: number;
    type: string;
    name: string;
}

const UnitPage = () => {
    usePageTitle('Unit');
    const token = localStorage.getItem('token') || '';
    const [loading, setLoading] = useState(false);
    const [unitData, setUnitData] = useState<UnitData[]>([]);
    const [unitDetail, setUnitDetail] = useState<UnitDetail | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [zeroData, setZeroData] = useState<string | undefined>('');
    const [showDetailData, setShowDetailData] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [newUnit, setNewUnit] = useState<UnitDetail>({
        id: 0,
        type: '',
        name: ''
    });

    console.log(newUnit, 'new unit test');


    useEffect(() => {
        if (token) {
            getUnit(1);
        }
    }, [token]);

    const getUnit = async (page: number) => {
        setLoading(true);
        const res = await apiProvider.getUnit(token,page);
        if (res.status === 200 && res.data && res.data.data) {
            const { data } = res.data.data;
            setUnitData(data || []);
            setCurrentPage(res.data.data.current_page);
            setTotalItems(res.data.data.total);
            setPageSize(res.data.data.per_page);
            setZeroData('');
        } else {
            setUnitData([]);
            setZeroData(res.data?.message || 'Tidak ada data tersedia');
        }
        setLoading(false);
    };

    const loadUnitDetail = async (id: number) => {
        const response = await apiProvider.getUnitDetail(token, id);
        if (response.status === 200) {
            setUnitDetail(response.data.data);
            setShowDetailData(true);
        } else {
            console.error('Gagal mendapatkan detail unit:', response.message);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (unitDetail) {
            const { name, value } = e.target;
            setUnitDetail({
                ...unitDetail,
                [name]: value
            });
        }
    };

    const handleSave = async () => {
        if (unitDetail) {
            const formData = new FormData();
            formData.append('type', unitDetail.type);
            formData.append('name', unitDetail.name);

            const response = await apiProvider.updateUnit(token, unitDetail.id, formData);
            if (response.status === 200) {
                setShowDetailData(false);
                getUnit(currentPage);
                message.success(response.data.message)

            } else {
                console.error('Gagal memperbarui unit:', response.message);
            }
        }
    };

    const handleDelete = async (id: number) => {
        const response = await apiProvider.deleteUnit(token, id);
        if (response.status === 200) {
            getUnit(currentPage);
            message.success(response.data.message)

        } else {
            console.error('Gagal menghapus unit:', response.message);
        }
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    const handlePaginationChange = (page: number) => {
        setCurrentPage(page);
        getUnit(page);
    };


    const handleAddUnitChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewUnit(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddUnit = async () => {
        if (newUnit && newUnit.type && newUnit.name) {
            const data = {
                type: newUnit.type,
                name: newUnit.name
            };

            const jsonData = JSON.stringify(data);


            const response = await apiProvider.addUnit(jsonData, token);
            console.log('jsonData:', response);


            if (response.status === 201) {
                setNewUnit({ id: 0, type: '', name: '' });
                setShowAddModal(false);
                getUnit(currentPage);
                message.success(response.data.message);
            } else {
                console.error('Gagal menambahkan unit:', response.message);
            }
        } else {
            message.error('Tipe dan Nama tidak boleh kosong');
        }
    };



    return (
        <>
            <KTCard className='mt-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Semua Unit</h3>
                    </div>
                </div>
                <div className='separator separator mb-4'></div>

                <div className='card-body py-3'>
                    <div className='mb-3'>
                        <Button variant='primary' onClick={() => setShowAddModal(true)}>
                            Tambah Unit
                        </Button>
                    </div>
                    <div className='table-responsive'>
                        <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        >
                            <thead>
                                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center'>
                                    <th className='ps-4 rounded-start'>Tipe</th>
                                    <th>Nama</th>
                                    <th className='pe-4 rounded-end'>Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unitData.length > 0 ? (
                                    unitData.map((item) => (
                                        <tr key={item.id} className='text-center'>
                                            <td className='ps-4'>
                                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    {item.type}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    {item.name}
                                                </div>
                                            </td>
                                            <td>
                                                <Button
                                                    variant='primary'
                                                    size='sm'
                                                    className='me-4'
                                                    onClick={() => {
                                                        loadUnitDetail(item.id);
                                                    }}
                                                >
                                                    Detail
                                                </Button>

                                                <Popconfirm
                                                    title={`Apakah Anda yakin untuk menghapus unit ${item.name} ini?`}
                                                    onConfirm={() => handleDelete(item.id)}
                                                    okText='Ya'
                                                    cancelText='Tidak'
                                                >
                                                    <Button
                                                        variant='danger'
                                                        size='sm'
                                                    >
                                                        Hapus
                                                    </Button>
                                                </Popconfirm>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8}>
                                            <Empty description={zeroData || 'Tidak ada data'} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className='card-footer d-flex justify-content-between align-items-center'>
                            <div>
                                {totalItems > 0 && (
                                    <span className='text-bold'>
                                        {`${(currentPage - 1) * pageSize + 1}-${Math.min(
                                            currentPage * pageSize,
                                            totalItems
                                        )} dari ${totalItems} Unit`}
                                    </span>
                                )}
                            </div>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={pageSize}
                                onChange={handlePaginationChange}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>

                {/* Modal Detail */}
                <Modal show={showDetailData} onHide={() => setShowDetailData(false)} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>{editMode ? 'Edit Unit' : 'Detail Unit'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {unitDetail ? (
                            <form>
                                <div className='mb-3'>
                                    <label className='col-form-label'>Tipe:</label>
                                    <select
                                        name='type'
                                        className='form-select'
                                        value={unitDetail.type}
                                        onChange={handleInputChange}
                                        disabled={!editMode}
                                    >
                                        <option value='' disabled>Pilih Tipe</option>
                                        <option value='Dasar'>Dasar</option>
                                        <option value='Konversi'>Konversi</option>
                                    </select>
                                </div>

                                <div className='mb-3'>
                                    <label className='col-form-label'>Nama:</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='name'
                                        value={unitDetail.name}
                                        onChange={handleInputChange}
                                        disabled={!editMode}
                                    />
                                </div>
                            </form>
                        ) : (
                            <p>Memuat detail unit...</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {editMode ? (
                            <>
                                <Button variant='secondary' onClick={handleCancel}>
                                    Batal
                                </Button>
                                <Button variant='primary' onClick={handleSave}>
                                    Simpan
                                </Button>
                            </>
                        ) : (
                            <Button variant='primary' onClick={() => setEditMode(true)}>
                                Edit
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>

                {/* Modal Tambah Unit */}
                <Modal show={showAddModal} onHide={() => setShowAddModal(false)} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Unit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-3'>
                            <label className='col-form-label'>Tipe:</label>
                            <select
                                name='type'
                                className='form-select'
                                value={newUnit.type}
                                onChange={handleAddUnitChange}
                            >
                                <option value='' disabled>Pilih Tipe</option>
                                <option value='Dasar'>Dasar</option>
                                <option value='Konversi'>Konversi</option>
                            </select>
                        </div>

                        <div className='mb-3'>
                            <label className='col-form-label'>Nama:</label>
                            <input
                                type='text'
                                className='form-control'
                                name='name'
                                value={newUnit.name}
                                onChange={handleAddUnitChange}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowAddModal(false)}>
                            Batal
                        </Button>
                        <Button variant='primary' onClick={handleAddUnit}>
                            Simpan
                        </Button>
                    </Modal.Footer>
                </Modal>
            </KTCard>
        </>
    );
};

export default UnitPage;
