import React, {useEffect, useState} from 'react'
import usePageTitle from '../../functions/global/usePageTitle'
import {Col, Form, Row, Button} from 'react-bootstrap'
import {KTCard} from '../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import apiProvider from '../../functions/global/apiProvider'
import {message} from 'antd'

interface CashierData {
  name: string
  address: string
  phone: string
}

const SettingPage = () => {
  usePageTitle('Setting Nota')
  const token = localStorage.getItem('token') || ''
  const [loading, setLoading] = useState(false)
  const [dataSettings, setSettingsData] = useState<CashierData>({
    name: '',
    address: '',
    phone: '',
  })

  useEffect(() => {
    if (token) {
      getSettings()
    }
  }, [token])

  const getSettings = async () => {
    setLoading(true)
    const res = await apiProvider.getSettings(token)
    if (res.status === 200 && res.data && res.data.data) {
      setSettingsData(res.data.data)
    } else {
      toast.error('Failed to fetch settings.')
    }
    setLoading(false)
  }

  const handleSaveSettings = async () => {
    setLoading(true)
    const res = await apiProvider.updateSettings(token, dataSettings)
    if (res.status === 200) {
      message.success('Settings updated successfully.')
    } else {
      toast.error('Failed to update settings.')
    }
    setLoading(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setSettingsData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  return (
    <>
      <KTCard>
        <div className='container px-12 pt-6'>
          <h2 className='text-center mb-8'>Setting Nota</h2>
          <div className='d-flex justify-content-center mb-6'>
            <Form style={{width: '720px'}}>
              <Form.Group as={Row} className='mb-3' controlId='formName'>
                <Form.Label column sm='4' className='fw-bold'>
                  Nama Toko
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    type='text'
                    name='name'
                    value={dataSettings.name}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3' controlId='formAddress'>
                <Form.Label column sm='4' className='fw-bold'>
                  Alamat Toko
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    type='text'
                    name='address'
                    value={dataSettings.address}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3' controlId='formPhone'>
                <Form.Label column sm='4' className='fw-bold'>
                  Nomor Telepon
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    type='text'
                    name='phone'
                    value={dataSettings.phone}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <div className='d-flex justify-content-center mt-8'>
                <Button variant='primary' size='lg' onClick={handleSaveSettings} disabled={loading}>
                  {loading ? 'Saving...' : 'Simpan'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <ToastContainer position='top-right' autoClose={2000} />
      </KTCard>
    </>
  )
}

export default SettingPage
