import React, {useEffect, useState} from 'react'
import usePageTitle from '../../functions/global/usePageTitle'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../_metronic/helpers'
import SearchDropdown from '../cassier/components/SearchDropdown'
import {
  addCategory,
  addNewUnit,
  deleteCategory,
  deleteUnit,
  fetchCategories,
  updateCategory,
  updateUnit,
} from '../../functions/global/api'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import {Empty, Pagination} from 'antd'

const API_URL = process.env.REACT_APP_API_URL

const KategoriProdukPage = () => {
  usePageTitle('Kategori Produk')

  const [category, setCategory] = useState<any[]>([])
  const [showEditConfirmation, setShowEditConfirmation] = useState(false)
  const [categoryToEdit, setCategoryToEdit] = useState<number | null>(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState<number | null>(null)
  const [unit, setUnit] = useState<any[]>([])
  const [showEditUnit, setShowEditUnit] = useState(false)
  const [unitToEdit, setUnitToEdit] = useState<number | null>(null)
  const [showDeleteUnit, setShowDeleteUnit] = useState(false)
  const [unitToDelete, setUnitToDelete] = useState<number | null>(null)
  // const [showCategoryConfirmation, setShowCategoryConfirmation] = useState(false);
  // const [categoryToCategory, setCategoryToCategory] = useState<number | null>(null);
  const [newCategory, setNewCategory] = useState({
    category_name: '',
    code: '',
  })
  const [newUnit, setNewUnit] = useState({
    unit_name: '',
    unit_value: 0,
  })

  const showData = () => {
    fetchCategories().then((data) => {
      setCategory(data)
    })
  }
  useEffect(() => {
    showData()
  }, [])

  useEffect(() => {
    if (showEditConfirmation && categoryToEdit !== null) {
      const selectedSupplier = category.find((category) => category.id === categoryToEdit)

      if (selectedSupplier) {
        setNewCategory({
          category_name: selectedSupplier.category_name,
          code: selectedSupplier.code,
        })
      }
    }
  }, [showEditConfirmation, categoryToEdit, category])

  const addProduct = async () => {
    try {
      const response = await addCategory({
        category_name: newCategory.category_name,
        code: newCategory.code,
      })

      console.log('Response:', response)
      if (response.status === 'success') {
        console.log('Product created successfully')
        showData()
        setCategory([...category, response.data])
        toast.success('Kategori telah ditambah', {position: toast.POSITION.TOP_RIGHT})
        setNewCategory({
          category_name: '',
          code: '',
        })
      } else {
        console.error('Gagal menambahkan produk, respons:', response)
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat menambahkan produk:', error)
    }
  }

  const handleDelete = async (category_id: number) => {
    try {
      const response = await deleteCategory(category_id)

      if (response.status === 200) {
        const updatedCategory = category.filter((item) => item.id !== category_id)
        setCategory(updatedCategory)
        toast.success('Kategori telah dihapus', {position: toast.POSITION.TOP_RIGHT})
      } else {
        console.error('Gagal menghapus produk, respons:', response)
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat menghapus produk:', error)
    }
  }

  const handleDeleteUnit = async (unit_id: number) => {
    try {
      const response = await deleteUnit(unit_id)

      if (response.status === 200) {
        const updatedUnit = unit.filter((item) => item.id !== unit_id)
        setUnit(updatedUnit)
        toast.success('Unit telah dihapus', {position: toast.POSITION.TOP_RIGHT})
      } else {
        console.error('Gagal menghapus produk, respons:', response)
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat menghapus produk:', error)
    }
  }

  const simpanPerubahanKategori = () => {
    if (categoryToEdit !== null) {
      console.log('Product Data:', categoryToEdit, newCategory)
      updateCategory(categoryToEdit, newCategory.category_name, newCategory.code)
        .then((response) => {
          console.log('Produk diperbarui:', response)
          showData()
          toast.success('Kategori telah diubah', {position: toast.POSITION.TOP_RIGHT})
        })
        .catch((error) => {
          console.error('Kesalahan mengedit produk:', error)
        })

      setShowEditConfirmation(false)
    }
  }

  const [arrangeBy, setArrangeBy] = useState('')
  const [sortBy, setSortBy] = useState('')

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)

  const handleSearch = (eventOrPageNumber: React.MouseEvent<HTMLButtonElement> | number) => {
    if (typeof eventOrPageNumber === 'number') {
      const pageNumber = eventOrPageNumber

      const searchParams = {
        category_name: newCategory.category_name,
        code: newCategory.code,
        arrange_by: arrangeBy,
        sort_by: sortBy,
      }

      axios
        .get(`${API_URL}/master/category?category_name=&code=&arrange_by=&sort_by=`, {
          params: {...searchParams, page: pageNumber},
        })
        .then((response) => {
          setCategory(response.data.data)
          setCurrentPage(pageNumber)
          setTotalPages(response.data.data)
        })
        .catch((error) => {
          console.error('Error searching products:', error)
        })
    } else {
    }
  }

  const openEditConfirmation = (category_id: number) => {
    setCategoryToEdit(category_id)
    setShowEditConfirmation(true)
  }

  const openDeleteConfirmation = (category_id: number) => {
    setCategoryToDelete(category_id)
    setShowDeleteConfirmation(true)
  }

  return (
    <>
      <KTCard className='mb-10'>
        <div className='card card-flush pt-3 mb-6 mb-lg-6'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Filter</h3>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row fw-bold'>
              <div className='col-3'>
                <label className='mb-2'>Nama Kategori</label>
                <input
                  placeholder='Masukkan nama kategori'
                  className='form-control'
                  type='text'
                  name='search'
                  onChange={(e) => setNewCategory({...newCategory, category_name: e.target.value})}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Kode Kategori</label>
                <input
                  placeholder='Masukkan kode kategori'
                  className='form-control'
                  type='text'
                  name='search'
                  onChange={(e) => setNewCategory({...newCategory, code: e.target.value})}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Urutan</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value='asc'>A-Z</option>
                  <option value='desc'>Z-A</option>
                </select>
              </div>
              <div className='col-3 mt-8 gap-xl'>
                <Button className='btn btn' onClick={() => handleSearch(1)}>
                  Cari
                </Button>
              </div>
            </div>
          </div>
        </div>
      </KTCard>

      <KTCard>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Stok Produk</h3>
          </div>
        </div>
        <div className='separator separator mb-4'></div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <div className='d-flex align-items-center position-relative mb-6'>
              <div
                className='col-3'
                data-bs-toggle='modal'
                data-bs-target='#ModalLabel'
                data-bs-whatever='@mdo'
              >
                <Button className='m-grid-responsive-xs btn btn-sm'>Tambah Kategori</Button>
              </div>
            </div>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center'>
                  <th className='ps-4 rounded-start'>No</th>
                  <th>Nama Kategori</th>
                  <th>Kode Kategori</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {Array.isArray(category) ? (
                  category.map((item, index) => (
                    <tr key={index}>
                      <td className='text-center ps-4'>{index + 1}</td>
                      <td className='text-center'>
                        <button type='button' className='btn btn-text'>
                          <a className='text-dark fw-bold  fs-6'>{item.category_name}</a>
                        </button>
                      </td>
                      <td className='text-center'>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>{item.code}</div>
                      </td>
                      <td className='text-center'>
                        <button
                          className='btn btn-info btn-sm'
                          onClick={() => openEditConfirmation(item.id)}
                        >
                          Edit
                        </button>
                        <span> </span>
                        <button
                          className='btn btn-danger btn-sm'
                          onClick={() => openDeleteConfirmation(item.id)}
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <Empty description='Data tidak tersedia' />
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* pagination */}
          <div className='row mb-6 mt-6'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <Pagination
                current={currentPage}
                total={totalPages * 10} // Total item, bukan total halaman
                onChange={(page) => handleSearch(page)}
                showSizeChanger={false} // Menyembunyikan pilihan ukuran halaman
                pageSize={10} // Ukuran halaman, misalnya 10 item per halaman
              />
            </div>
          </div>
          {/* end::Table container */}

          {/* Alert Tambah Kategori */}
          <div
            className='modal fade'
            id='ModalLabel'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-xl'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h1 className='modal-title fs-5' id='exampleModalLabel'>
                    Tambah Kategori
                  </h1>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body'>
                  <form>
                    <div className='mb-3'>
                      <label className='col-form-label'>Nama Kategori:</label>
                      <input
                        type='text'
                        placeholder='Masukkan nama Kategori'
                        className='form-control'
                        id='product-name'
                        value={newCategory.category_name}
                        onChange={(e) =>
                          setNewCategory({...newCategory, category_name: e.target.value})
                        }
                      />
                    </div>
                    <div className='mb-3'>
                      <label className='col-form-label'>Kode Kategori:</label>
                      <input
                        type='text'
                        placeholder='Masukkan kode Kategori'
                        className='form-control'
                        id='product-name'
                        value={newCategory.code}
                        onChange={(e) => setNewCategory({...newCategory, code: e.target.value})}
                      />
                    </div>
                  </form>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                    Tutup
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    onClick={addProduct}
                  >
                    Tambah
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className='modal fade'
            id='deleteModal'
            aria-labelledby='deleteModal'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h1 className='modal-title fs-5' id='exampleModalLabel'>
                    Hapus Produk
                  </h1>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body'>
                  <p>Anda ingin menghapus Stok Ini ?</p>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                    Batal
                  </button>
                  <button type='button' className='btn btn-danger' data-bs-dismiss='modal'>
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End Alert Tambah Kategori */}
        </div>

        {/* Edit */}
        {showEditConfirmation && categoryToEdit !== null && (
          <Modal
            show={showEditConfirmation}
            onHide={() => setShowEditConfirmation(false)}
            size='xl'
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className='mb-3'>
                  <label className='col-form-label'>Nama Kategori:</label>
                  <input
                    type='text'
                    className='form-control'
                    value={newCategory.category_name}
                    onChange={(event) =>
                      setNewCategory({...newCategory, category_name: event.target.value})
                    }
                  />
                </div>
                <div className='mb-3'>
                  <label className='col-form-label'>Kode Kategori:</label>
                  <input
                    type='text'
                    className='form-control'
                    value={newCategory.code}
                    onChange={(event) => setNewCategory({...newCategory, code: event.target.value})}
                  />
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowEditConfirmation(false)}>
                Batal
              </Button>
              <Button variant='primary' onClick={simpanPerubahanKategori}>
                Simpan Perubahan
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Delete */}
        {showDeleteConfirmation && categoryToDelete !== null && (
          <div>
            <div className='modal-backdrop fade show'></div>
            <div className='modal fade show' style={{display: 'block'}}>
              <div className='modal-dialog modal-lg'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title' id='deleteConfirmationModalLabel'>
                      Apakah yakin anda ingin menghapus?
                    </h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => setShowDeleteConfirmation(false)}
                    ></button>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      data-bs-dismiss='modal'
                      onClick={() => setShowDeleteConfirmation(false)}
                    >
                      Batal
                    </button>
                    <button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => {
                        handleDelete(categoryToDelete)
                        setShowDeleteConfirmation(false)
                      }}
                    >
                      Setuju
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer position='top-right' autoClose={2000} />
      </KTCard>

      {/* SET UNIT */}
    </>
  )
}

export default KategoriProdukPage
