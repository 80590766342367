import React, {useEffect, useState} from 'react'
import usePageTitle from '../../functions/global/usePageTitle'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../_metronic/helpers'
import {fetchCategories, showTransaksi} from '../../functions/global/api'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {convertDate, convertIDR} from '../../functions/global'
import {Empty, Pagination} from 'antd'

const API_URL = process.env.REACT_APP_API_URL

interface Category {
  id: number
  category_name: string
}

const TransactionPage = () => {
  usePageTitle('Data Transaksi')

  const [transaksi, setTransaksi] = useState<any[]>([])
  const [categories, setCategories] = useState<Category[]>([])

  const navigate = useNavigate()
  const handleReturnButtonClick = (transaction_id: number) => {
    navigate(`/dataatransaksi/detailTransaksi/${transaction_id}`)
  }

  const showData = () => {
    showTransaksi()
      .then((data) => {
        const filteredTransactions = data.filter((transaction: { status: string }) => transaction.status !== 'hold');
        console.log('data',filteredTransactions);
        console.log('data',data);
        
        setTransaksi(filteredTransactions)
      })
      .catch((error) => {
        console.error('Error fetching suppliers:', error)
      })
  }

  useEffect(() => {
    fetchCategories().then((dataKategori) => {
      setCategories(dataKategori)
    })

    showData()
  }, [])

  const [arrangeBy, setArrangeBy] = useState('')
  const [status, setStatus] = useState('')

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [newTransaksi, setNewTransaksi] = useState({
    date: '',
    nota_number: '',
    category_id: '',
  })

  const handleSearch = (eventOrPageNumber: React.MouseEvent<HTMLButtonElement> | number) => {
    if (typeof eventOrPageNumber === 'number') {
      const pageNumber = eventOrPageNumber

      const searchParams = {
        date: newTransaksi.date,
        nota_number: newTransaksi.nota_number,
        category_id: newTransaksi.category_id,
        status: status,
        arrange_by: arrangeBy,
      }

      axios
        .get(`${API_URL}/transaction?page=&status=&date=&nota_number=&category_id=&arrange_by=`, {
          params: {...searchParams, page: pageNumber},
        })
        .then((response) => {
          setTransaksi(response.data.data.data)
          setCurrentPage(pageNumber)
          setTotalPages(response.data.data.data)
        })
        .catch((error) => {
          console.error('Error searching products:', error)
        })
    } else {
    }
  }

  return (
    <>
      <KTCard className='mb-10'>
        <div className='card card-flush pt-3 mb-6 mb-lg-6'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Filter</h3>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row fw-bold'>
              <div className='col-3'>
                <label className='mb-2'>No Nota</label>
                <input
                  placeholder='Masukkan no nota'
                  className='form-control'
                  type='text'
                  name='statusDeliveryCode'
                  onChange={(e) => setNewTransaksi({...newTransaksi, nota_number: e.target.value})}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Tanggal</label>
                <input
                  className='form-control'
                  type='date'
                  placeholder='dd/mm/yyyy'
                  name='date'
                  onChange={(e) => setNewTransaksi({...newTransaksi, date: e.target.value})}
                />
              </div>
              {/* <div className='col-3'>
                                <label className='mb-2'>Arrange by</label>
                                <select className="form-select"
                                    name='modeProcess'
                                    value={arrangeBy}
                                    onChange={(e) => setArrangeBy(e.target.value)}
                                >
                                    <option value='nota_number'>No Nota</option>
                                    <option value="category_id">Kategori</option>
                                    <option value="grand_total">Sub Total</option>
                                    <option value="date">Tanggal</option>
                                </select>
                            </div> */}
              <div className='col-3'>
                <label className='mb-2'>Status</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value=''>Pilih Status</option>
                  <option value='paid'>Terbayar</option>
                  <option value='return'>Pengembalian</option>
                  {/* <option value="hold">Tertahan</option> */}
                </select>
              </div>
              <div className='col-3'>
                <label className='mb-2'>Kategori</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={newTransaksi.category_id}
                  onChange={(e) => setNewTransaksi({...newTransaksi, category_id: e.target.value})}
                >
                  <option value=''>Pilih Kategori</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-3 mt-8'>
                <Button onClick={() => handleSearch(1)}>Cari</Button>
              </div>
            </div>
          </div>
        </div>
      </KTCard>

      <KTCard>
        {/* begin::Table container */}
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Data Transaksi</h3>
          </div>
        </div>
        <div className='separator separator'></div>
        <div className='card-body'>
          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center'>
                  <th className='ps-4 rounded-start'>No</th>
                  <th className='min-w-150px'>No Nota</th>
                  <th className='min-w-120px'>Tanggal</th>
                  {/* <th className='min-w-140px'>Nominal</th> */}
                  <th className='pe-4 rounded-end'>Sub Total</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {transaksi.length > 0 ? (
                  transaksi.map((transaksi, index) => (
                    <tr className='text-center' key={index}>
                      <td align='center'>{index + 1}</td>
                      <td>
                        <button type='button' className='btn btn-text'>
                          <a
                            className='text-dark fw-bold text-hover-primary fs-6'
                            onClick={() => handleReturnButtonClick(transaksi.id)}
                          >
                            {transaksi.nota_number}
                          </a>
                        </button>
                      </td>
                      <td className='text-dark fw-bold d-block mb-1 fs-6'>
                        {convertDate(transaksi.date)}
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Status: {transaksi.status}
                        </span>
                      </td>
                      <td>
                        <span style={{fontSize: '14px'}} className='badge badge-light-success'>
                          {convertIDR(transaksi.grand_total)}{' '}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className='text-center text-muted'>
                      <Empty
                        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                        imageStyle={{height: 60}}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* pagination */}
          <div className='row mb-6 mt-6'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <Pagination
                current={currentPage}
                total={totalPages * 10}
                onChange={(page) => handleSearch(page)}
                showSizeChanger={false}
                pageSize={10}
              />
            </div>
          </div>
        </div>
        {/* end::Table container */}
      </KTCard>
    </>
  )
}

export default TransactionPage
