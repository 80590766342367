import React, { useState, useEffect, useRef, ReactNode } from 'react'
import usePageTitle from '../../functions/global/usePageTitle'
import { convertIDR, formatCurrency } from '../../functions/global'
import {
  addInputsaldo,
  deleteTrans,
  getSearchProduk,
  getSearchProduk2,
  getsession,
  getToko,
  pushTransaksis,
  searchProduk,
  showMember,
  tahanTransaksihistory,
  updateProduct,
} from '../../functions/global/api'
import Pembayaran from '../apps/components/Pembayaran'
import TahanTransaksi from '../apps/components/TahanTransaksi'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { string } from 'yup'

interface Product {
  id: number
  code: string
  name: string
  tanggal: string
  jumlah: string
  diskon: string
  diskonprice: String
  defaultunit: string
  price: string
  product_id: string
  defaulprice: number
  unit_id: string
  subtotal: string
  hargaatingkat: any
  defaultdropddown:any
}
interface searchproduk {
  note: ReactNode
  stok: any
  id: number
  name: string
  code: string
  unitName: string
  price: number
  defaulprice: number
  jumlah: number
  product_id: string
  unit_id: string
  hargaatingkat: any
}

const POSPage = () => {
  usePageTitle('Dashboard')

  // const [show, setShow] = useState(false);
  const inputModalRef = useRef<HTMLInputElement | null>(null)
  const inputModalRef2 = useRef<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState('0')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [valuespcs, setValuespcs] = useState('0')
  const [valuemember, setValuemember] = useState('')
  const [transid, setTransid] = useState<any>(0)
let totalbeda =0;
  const [showModal, setShowModal] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [showTahanTransaksi, setShowTahanTransaksi] = useState(false)
  const [productQty, setProductQty] = useState<any>({})
  const [detailToko, setDetailToko] = useState<any>({})
  const [amountPay, setAmountPay] = useState('0')
  const [idtransaksi, setidTransaksi] = useState('0')
  const [amountPay1, setAmountPay1] = useState('0')
  const [amountPay3, setAmountPay2] = useState('0')
  const [selectedProduct, setSelectedProduct] = useState<Product | null | any>([])
  const [allProduck, setAllProduk] = useState<Product | null | any>([])
  const [member, setMember] = useState<any[]>([])
  const [filteredData, setFilteredData] = useState<searchproduk[]>([])
  const [tahanTransaksi, setTahantransaksi] = useState<any>([])
  const tableRef = useRef<HTMLTableElement>(null)
  const inputuang = useRef<HTMLInputElement | null>(null)
  const inputuang2 = useRef<HTMLInputElement | null>(null)
  const inputuang3 = useRef<HTMLInputElement | null>(null)
  const historiRef = useRef<HTMLButtonElement | null>(null)
  const tahanRef = useRef<HTMLButtonElement | null>(null)
  const inputRefs = useRef<Array<React.RefObject<HTMLInputElement>>>([])
  const inputdiskonRefs = useRef<Array<React.RefObject<HTMLInputElement>>>([])
  const inputdiskonRefs2 = useRef<Array<React.RefObject<HTMLInputElement>>>([])
  const dropdownRef = useRef<HTMLSelectElement>(null)
  const dropdownRef1 = useRef<HTMLSelectElement>(null)
  const dropdownRef2 = useRef<HTMLSelectElement>(null)

  console.log(selectedProduct, 'idd')


  const getalldaata = async () => {
    const session = await getsession()
    console.log('dataxxx', session.data.sesi)

    if (session.data.sesi === 'inactive') {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }

  const handlePaymentClick = () => {
    setShowPayment(true)
  }

  const handleClosePayment = () => {
    setShowPayment(false)
  }
  const handleClosePayment2 = () => {
    setShowPayment(false)
    setSelectedProduct([])
    setPaymentMethod('cash')
    setSplit('')
    setAmountPay('0')
    setAmountPay1('0')
    setAmountPay2('0')
    if (transid !== 0) {
      setTransid(0)
      getdatatransaksi()
    }
    TotalAmount()
    totalQty()
    inputModalRef2.current?.focus()
  }

  // "products": productDetails,
  // "member_id": idmember,
  // "total": totalQty,
  // "discount": 0,
  // "grand_total": totamount,
  // "change": amountPay - totamount,
  // "payment_discount": 2.5, // isi dengan nominal potongan pembayaran (menggunakan persentase) jika tidak ada isi dengan 0
  // "payment_method": paymentMethod !== 'split'?'non_split':'split' ,

  const handlertahan = async () => {
    if (selectedProduct.length !== 0) {
      const productDetails = selectedProduct.map(
        (product: {
          id_code: any
          id: any
          diskon: any
          hargaatingkat: any
          product_id: number
          unit_id: any
          price: number
          jumlah: number
          discount: number
        }) => ({
          product_id: product.id,
          code_id: product.id_code,
          // product_id: 1,
          unit_id: product.unit_id,
          // unit_id: 34,
          price: product.price,
          quantity_unit: product.jumlah,
          discount: product.diskon | 0,
          sub_total: product.price * product.jumlah,
        })
      )

      const transaksiData = {
        products: productDetails,
        member_id: valuemember,
        total: totalQty(),
        discount: 5000,
        payment_discount: valuespcs,
        grand_total: TotalAmount(),
        change: parseFloat(amountPay) - TotalAmount(),
        payment_method: paymentMethod !== 'split' ? 'non_split' : 'split',
        cash: 0,
        transfer: 0,
        qris: 0,
        status: 'hold',
      }
      console.log(transaksiData, 'data')

      const res = await pushTransaksis(transaksiData)

      console.log(res, 'dsasaasd')

      console.log('selectproduk', res.status)

      if (res.status === 'success') {
        getdatatransaksi()
        setShowPayment(false)
        setSelectedProduct([])
        toast.success(res.message)
      }
    } else {
      toast.error('Belum ada data', { position: toast.POSITION.TOP_RIGHT })
    }
  }
  const handleTahanTransaksiClick = () => {
    if (tahanTransaksi.length === 0) {
      toast.error('Data Masih Kosong', { position: toast.POSITION.TOP_RIGHT })
    } else {
      setShowTahanTransaksi(true)
    }
  }

  const handleCloseTahanTransaksi = () => {
    setShowTahanTransaksi(false)
  }

  useEffect(() => {
    getdatatransaksi()
    showData()
    allDAta()
  }, [])
  const allDAta = async () => {
    const value = ''
    const response = await getSearchProduk(value)
    const responseku = await getToko()
    setDetailToko(responseku.data)
    const data = response.data.data
    const last = response.data.last_page
    console.log('filename', data);

    let dt: string | undefined // atau jenis data yang sesuai
    dt = response.data.next_page_url


    const fillerData = data.flatMap((item: { codes: any[]; id: { toString: () => any }; product_name: any; stock: any; units: any[] }) =>
      item.codes.map((code: { code: any; note: any; id: any }) => ({
        id: item.id.toString(),
        name: item.product_name,
        code: code.code,
        note: code.note,
        id_code: code.id,
        stok: item.stock,
        hargaatingkat: item.units.map((unit: { id: any; name: any; conversion: any; price: any; multi_qty_1: any; multi_price_1: any; multi_qty_2: any; multi_price_2: any; multi_qty_3: any; multi_price_3: any; multi_price_4: any }) => ({
          id: unit.id,
          name: unit.name,
          conversion: unit.conversion,
          price: unit.price,
          multi_qty_1: unit.multi_qty_1,
          multi_price_1: unit.multi_price_1,
          multi_qty_2: unit.multi_qty_2,
          multi_price_2: unit.multi_price_2,
          multi_qty_3: unit.multi_qty_3,
          multi_price_3: unit.multi_price_3,
          multi_qty_4: unit.multi_price_4
        })),
        unitName: item.units.map((unit: { name: any }) => unit.name).join(', '),
        defaulprice: item.units[0]?.price || 0,
        price: item.units[0]?.price || 0,
        jumlah: item.units[0]?.conversion || 0,
        product_id: item.id.toString(),
        defaultunit: item.units[0]?.name || '',
        unit_id: item.units[0]?.id.toString() || ''
      }))
    );
    setAllProduk(fillerData)
    console.log('filename', fillerData);

    if (last !== 1) {
      for (let i = 1; i < last; i++) {
        const res = await getSearchProduk2(dt)
        const datas = res.data.data
        dt = res.data.next_page_url
        console.log('dat', datas)
        const fillerDatas = datas.flatMap((item: { codes: any[]; id: { toString: () => any }; product_name: any; stock: any; units: any[] }) =>
          item.codes.map((code: { code: any; note: any; id: any }) => ({
            id: item.id.toString(),
            name: item.product_name,
            code: code.code,
            note: code.note,
            id_code: code.id,
            stok: item.stock,
            hargaatingkat: item.units.map((unit: { id: any; name: any; conversion: any; price: any; multi_qty_1: any; multi_price_1: any; multi_qty_2: any; multi_price_2: any; multi_qty_3: any; multi_price_3: any; multi_price_4: any }) => ({
              id: unit.id,
              name: unit.name,
              conversion: unit.conversion,
              price: unit.price,
              multi_qty_1: unit.multi_qty_1,
              multi_price_1: unit.multi_price_1,
              multi_qty_2: unit.multi_qty_2,
              multi_price_2: unit.multi_price_2,
              multi_qty_3: unit.multi_qty_3,
              multi_price_3: unit.multi_price_3,
              multi_qty_4: unit.multi_price_4
            })),
            unitName: item.units.map((unit: { name: any }) => unit.name).join(', '),
            defaulprice: item.units[0]?.price || 0,
            price: item.units[0]?.price || 0,
            jumlah: item.units[0]?.conversion || 0,
            product_id: item.id.toString(),
            defaultunit: item.units[0]?.name || '',
            unit_id: item.units[0]?.id.toString() || ''
          }))
        )
        setAllProduk((prevAllProduk: any) => [...prevAllProduk, ...fillerDatas])
        console.log('i', i)
      }
    }
  }
  const showData = () => {
    showMember().then((data) => {
      setMember(data)
    })
  }
  useEffect(() => {
    if (showModal === true) {
      if (inputModalRef.current) {
        inputModalRef.current.focus()
      }
    } else {
      if (inputModalRef2.current) {
        inputModalRef2.current.focus()
      }
    }
  }, [showModal])
  const getdatatransaksi = async () => {
    const restransasksi = await tahanTransaksihistory()
    console.log('setu', restransasksi);

    setTahantransaksi(restransasksi.data.data)
  }
  const handleInputChange = (e: any) => {
    const number = formatCurrency(e.target.value)
    console.log('nilaai', number)

    setInputValue(number)
  }
  const handleInputChange2 = (e: any) => {
    const number = formatCurrency(e.target.value)
    console.log('nilaai', number)

    setAmountPay(number)
  }
  const handleInputChange3 = (e: any) => {
    const number = formatCurrency(e.target.value)
    console.log('nilaai', number)

    setAmountPay1(number)
  }
  const handleInputChange4 = (e: any) => {
    const number = formatCurrency(e.target.value)
    console.log('nilaai', number)
    setAmountPay2(number)
  }
  const getSelectedRange = (qty: number, product: any) => {
    console.log('rnge', product)

    // Filter hargaatingkat untuk mendapatkan item yang sesuai dengan defaultunit
    const selectedUnit = product.hargaatingkat.find(
      (item: any) => item.name === product.defaultunit
    )

    if (!selectedUnit) {
      throw new Error(`No price level found for unit ${product.defaultunit}`)
    }

    // Flatten the selected unit ranges into a single array with all possible multi_qty and multi_price combinations
    const ranges = []

    ranges.push({
      minimum: 1,
      price: selectedUnit.price,
    });

    // Iterasi untuk menambahkan multi_qty dan multi_price ke ranges
    for (let i = 1; i <= 4; i++) {
      if (selectedUnit[`multi_qty_${i}`] && selectedUnit[`multi_price_${i}`]) {
        ranges.push({
          minimum: selectedUnit[`multi_qty_${i}`],
          price: selectedUnit[`multi_price_${i}`],
        })
      }
    }
    ranges.sort((a, b) => b.minimum - a.minimum)

    // Sort the ranges by minimum quantity in descending order
    // ranges.sort((a: any, b: any) => b.minimum - a.minimum);

    console.log('ranges', ranges)
    ranges.sort((a, b) => a.minimum - b.minimum)

    // Find the appropriate range
    let selectedRange = ranges[0] // Default to the first range if no match
    for (let i = ranges.length - 1; i >= 0; i--) {
      if (qty >= ranges[i].minimum) {
        selectedRange = ranges[i];
        break;
      }
    }
    return selectedRange
  }

  const calculateUnitPrice = (hargaUnit: any, qty: number) => {
    if (qty >= hargaUnit.minimum) {
      return hargaUnit.price / hargaUnit.minimum
    }
    return hargaUnit.price
  }
  // Function to close the modal
  const closeModal = async () => {
    if (parseInt(inputValue) > 0) {
      // If input is not empty, close the modal
      const convertedNumber = parseInt(inputValue.replace(/,/g, ''), 10)
      const response = await addInputsaldo(convertedNumber)
      if (response.status == 'success') {
        setShowModal(false)
        localStorage.setItem('drawer', inputValue)
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT })
      } else {
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT })
      }
    } else {
      // If input is empty, you can show an error message or take other actions
      alert('Input cannot be empty')
    }
  }
  const handledeletetrraans = async (data: number) => {
    const res = await deleteTrans(data)
    console.log('iso', res.status);
    if (res.status === "success") {
      getdatatransaksi();
      toast.success('Berhasil Hapus Transaksi')
      setShowTahanTransaksi(false)
    }

  }
  const handleSearchProduct = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    const response = await getSearchProduk(value);
    const data = response.data.data;
    console.log('ddatarespons',data);
    
    const fillerData = data.flatMap((item: { codes: any[]; id: { toString: () => any }; product_name: any; stock: any; units: any[] }) =>
      item.codes.map((code: { code: any; note: any; id: any }) => ({
        id: item.id.toString(),
        name: item.product_name,
        code: code.code,
        note: code.note,
        id_code: code.id,
        stok: item.stock,
        hargaatingkat: item.units.map((unit: { id: any; name: any; conversion: any; price: any; multi_qty_1: any; multi_price_1: any; multi_qty_2: any; multi_price_2: any; multi_qty_3: any; multi_price_3: any; multi_price_4: any }) => ({
          id: unit.id,
          name: unit.name,
          conversion: unit.conversion,
          price: unit.price,
          multi_qty_1: unit.multi_qty_1,
          multi_price_1: unit.multi_price_1,
          multi_qty_2: unit.multi_qty_2,
          multi_price_2: unit.multi_price_2,
          multi_qty_3: unit.multi_qty_3,
          multi_price_3: unit.multi_price_3,
          multi_qty_4: unit.multi_price_4
        })),
        unitName: item.units.map((unit: { name: any }) => unit.name).join(', '),
        defaulprice: item.units[0]?.price || 0,
        price: item.units[0]?.price || 0,
        jumlah: item.units[0]?.conversion || 0,
        product_id: item.id.toString(),
        defaultunit: item.units[0]?.name || '',
        unit_id: item.units[0]?.id.toString() || ''
      }))
    );

    console.log(filteredData);
    setFilteredData(fillerData);
  };

  const handleSelectProduct = (product: any) => {

    const existingProduct = selectedProduct.find((item: Product) => {
      // Debugging the item properties
      console.log('item.id:', item.id, 'item.code:', item.code)
      return item.id === product.id && item.code === product.code
    })

    if (existingProduct) {
      setSelectedIndex(0);
      const updatedProducts = selectedProduct.map((item: Product) => {
        if (item.id === product.id && item.code === product.code) {
          const defaultPrice =
            product.hargaatingkat.find((unit: { name: string }) => unit.name === product.defaultunit)
              ?.price ||
            product.hargaatingkat[0]?.price ||
            1

          const minimumPcs =
            product.hargaatingkat.find((unit: { name: string }) => unit.name === product.defaultunit)
              ?.minimum || 1

          const updatedQuantity = parseInt(item.jumlah) + minimumPcs
          console.log('updatedQuantity', updatedQuantity)
          console.log('item.jumlah', item.jumlah)

          const updatedProductQty = { ...productQty, [product.id + product.code]: updatedQuantity }
          setProductQty(updatedProductQty)

          const priceTier = product.hargaatingkat.find(
            (unit: { name: string; minimum: number; price: number }) =>
              updatedQuantity >= unit.minimum
          )

          const updatedSubtotal = priceTier
            ? priceTier.price * updatedQuantity
            : updatedQuantity * product.hargaatingkat[0].price

          const hargaunit = getSelectedRange(updatedQuantity, product)
          const unitPrice = calculateUnitPrice(hargaunit, updatedQuantity)

          return {
            ...item,
            jumlah: String(updatedQuantity),
            defaultPrice: unitPrice,
            subtotal: updatedSubtotal,
          }
        }
        return item
      })
      // setSelectedProduct(updatedProducts)
    } else {
      setSelectedIndex(0);
      const hargaPcs = product.hargaatingkat[0].price
      const jumlahPcs = product.hargaatingkat[0].conversion
      const defaultPrice = product.hargaatingkat[0].price
      const updatedProduct: Product = {
        ...product,
        jumlah: String(jumlahPcs),
        price: String(hargaPcs),
        defaultunit: product.hargaatingkat[0].name,
        defaultPrice: defaultPrice,
        defaultdropddown:product.hargaatingkat[0],
        code: product.code,
      }
      console.log('defult',updateProduct);
      
      setSelectedProduct([...selectedProduct, updatedProduct])
    }
    setSearchValue('')
  }

  const handleSelectProduct2 = (prodproduct: any) => {
    const detailsOnly = prodproduct.details.map(
      (detail: {
        quantity_unit: any
        id: any
        transaction_id: any
        product_id: any
        code_id: any
        price: any
        quantity: any
        discount: any
        unit_id: any
        sub_total: any
      }) => ({
        id: detail.id,
        transaction_id: detail.transaction_id,
        product_id: detail.product_id,
        price: detail.price,
        unit_id: detail.unit_id,
        quantity: detail.quantity,
        quantity_unit: detail.quantity_unit,
        discount: detail.discount,
        sub_total: detail.sub_total,
      })
    )
    console.log('teko', detailsOnly);
    console.log('teko', prodproduct.details);
    console.log('teko', allProduck);

    const foundProducts = detailsOnly.map(
      (detail: {
        quantity_unit: any
        transaction_id: number;
        price: any;
        quantity: any;
        product_id: any;
        unit_id: any;
        sub_total: any
      }) => {
        console.log('romansa', detail);
        
        const productId = detail.product_id.toString(); 
        const foundProduct = allProduck.find(
          (product: { id: any }) => product.id.toString() === productId
        );
        const foundProduct2 = foundProduct.hargaatingkat.find(
          (product: { id: any }) => product.id.toString() === detail.unit_id.toString()
        );
        setdefaultunit(foundProduct2)
        // Optionally, you can include additional details from the detail object
        setTransid(detail.transaction_id)
        return {
          ...foundProduct,
          jumlah: detail.quantity_unit,
          price: detail.price,
          defaulprice: detail.price,
          defaultdropddown:foundProduct2,
          subtotal: detail.sub_total,

        }
      }
    )

    console.log('data1', foundProducts)

    // if (selectedProduct.length !== 0) {
    //   let prod =[];
    //   prod.push(foundProducts)
    //   console.log('selected',selectedProduct);
    //   console.log('selected',prod);
    //   setSelectedProduct(foundProducts)

    // } else {
    //   setSelectedProduct([...selectedProduct, foundProducts]);
    // }
    const transformedProducts = foundProducts.map(
      (prod: {
        jumlah: any
        code: any
        id: any
        quantity: any
        name: any
        price: any
        product_id: { toString: () => any }
        quantity_unit: any
        unit_id: { toString: () => any }
      }) => ({
        code: prod.code,
        id: prod.id,
        jumlah: prod.jumlah,
        name: prod.name,
        price: prod.price,
        product_id: prod.product_id,
        unitName: prod.quantity_unit,
        unit_id: prod.unit_id,
      })
    )
    console.log('dataa4', transformedProducts)

    setSelectedProduct(foundProducts)
    setSearchValue('')
  }

  const findPercentage = (amount: number, total: number): number => {
    if (total === 0) {
      return 0 // Avoid division by zero
    }

    const percentage = (amount / total) * 100
    return percentage
  }

  const handlediskon = (productId: string, qty: string, jenis: string, code: string) => {
    if (jenis === 'present') {
      const newSubtotal = selectedProduct.find(
        (product: { id: string; code: string }) => product.id === productId && product.code === code
      ).price
      const diskonpersen = calculateDiskonPrice(newSubtotal, parseInt(qty))
      const updatedSelectedProduct = selectedProduct.map((product: { id: string; code: string }) => {
        if (product.id === productId && product.code === code) {
          return { ...product, diskon: qty, diskonprice: diskonpersen }
        }
        return product
      })
      setSelectedProduct(updatedSelectedProduct)
    } else {
      const newSubtotal = selectedProduct.find(
        (product: { id: string }) => product.id === productId
      ).price
      const discountAmount = parseFloat(qty) || 0
      const totalAmount = parseFloat(newSubtotal) || 0
      const discountPercentage = findPercentage(discountAmount, totalAmount)
      const updatedSelectedProduct = selectedProduct.map((product: { id: string }) => {
        if (product.id === productId) {
          return { ...product, diskonprice: qty, diskon: discountPercentage }
        }
        return product
      })
      setSelectedProduct(updatedSelectedProduct)
    }
  }
  const handleChangeQty = (productId: string, productCode: string, qty: string) => {
    const productKey = `${productId}-${productCode}`
    const updatedProductQty = { ...productQty, [productKey]: qty }
    console.log('dataanya', updatedProductQty)
    console.log('helo', selectedProduct)

    if (parseInt(qty) < 1) {
      alert('minimal 1')
      setProductQty({ ...productQty, [productKey]: '1' })
      qty = '1'
    } else {
      setProductQty(updatedProductQty)
    }

    const updatedSelectedProduct = selectedProduct.map(
      (product: { hargaatingkat: any; id: string; code: string }) => {
        if (product.id === productId && product.code === productCode) {
          const quantity = parseInt(qty)
          const hargaUnit = getSelectedRange(quantity, product)
          console.log('hrgaunit', hargaUnit)

          const unitPrice = calculateUnitPrice(hargaUnit, quantity)
          console.log('unitprice2', unitPrice)

          const subtotal = unitPrice * quantity
          console.log('unitprice', subtotal)

          return {
            ...product,
            jumlah: qty,
            price: hargaUnit.price,
            defaulprice: unitPrice,
          }
        }
        return product
      }
    )

    console.log('isis', updatedSelectedProduct)
    setSelectedProduct(updatedSelectedProduct)
  }

  const handleRemoveItem = (id: number, code: string) => {
    const updatedTableData = selectedProduct.filter(
      (product: Product) => !(product.id === id && product.code === code)
    )

    setProductQty((prevProductQty: any) => {
      const updatedProductQty = { ...prevProductQty }
      delete updatedProductQty[`${id}-${code}`]
      return updatedProductQty
    })

    if (updatedTableData.length === 0) {
      setTransid(0)
    }

    setSelectedProduct(updatedTableData)
  }

  const datashowdata = (product: any) => {
    handleSelectProduct2(product)
  }

  const subTotal = (qty: string, diskon: string, price: string, subtotal: string) => {
    const disdata = parseInt(diskon) || 0
    let totalAmount = parseFloat(qty) * parseFloat(price)
    if (disdata !== 0) {
      let discountAmount = (totalAmount * disdata) / 100
      totalAmount = parseInt(diskon) === 0 ? totalAmount : totalAmount - discountAmount
      return subtotal === 'ya' ? Number.isNaN(discountAmount) ? 0 : discountAmount : Number.isNaN(totalAmount) ? 0 : totalAmount
    } else {
      totalAmount = totalAmount
      console.log('pricce', totalAmount);
      return Number.isNaN(totalAmount) ? 0 : totalAmount
    }
  }

  const totalQty = () => {
    let total = 0
    selectedProduct.map((product: { jumlah: string }) => {
      total += parseInt(product.jumlah)
    })
    return total
  }
  const calculateDiskonPrice = (originalPrice: number, persentaseDiskon: number) => {
    const diskonAmount = (originalPrice * persentaseDiskon) / 100
    const diskonPrice = originalPrice - diskonAmount
    return diskonAmount
  }
  
  const calculateTotalQuantityAndBestPriceById = (products: Product[]) => {
    const resultById: { [key: string]: { totalQuantity: number; price: number } } = {};
  
    products.forEach((product) => {
      const { id, jumlah, defaultdropddown, hargaatingkat } = product;
      const conversion = defaultdropddown.conversion;
      const quantityInSmallestUnit = parseInt(jumlah, 10) * conversion; // Konversi jumlah ke unit terkecil
  
      if (resultById[id]) {
        resultById[id].totalQuantity += quantityInSmallestUnit;
      } else {
        resultById[id] = {
          totalQuantity: quantityInSmallestUnit,
          price: defaultdropddown.price, // Inisialisasi harga dengan harga dasar
        };
      }
  
      // Tentukan harga terbaik berdasarkan total quantity dan harga tingkat
      const totalQuantity = resultById[id].totalQuantity;
      let bestPrice = defaultdropddown.price;
  
      hargaatingkat.forEach((harga: { multi_qty_3: number; multi_price_3: number; multi_qty_2: number; multi_price_2: number; multi_qty_1: number; multi_price_1: number }) => {
        if (totalQuantity >= harga.multi_qty_3 && harga.multi_price_3 > 0) {
          bestPrice = Math.min(bestPrice, harga.multi_price_3);
        }
        if (totalQuantity >= harga.multi_qty_2 && harga.multi_price_2 > 0) {
          bestPrice = Math.min(bestPrice, harga.multi_price_2);
        }
        if (totalQuantity >= harga.multi_qty_1 && harga.multi_price_1 > 0) {
          bestPrice = Math.min(bestPrice, harga.multi_price_1);
        }
      });
  
      resultById[id].price = bestPrice;
    });
  
    return resultById;
  };
  

  const calculateTotalHargaKeseluruhan = (resultById: { [key: string]: { totalQuantity: number; price: number } }) => {
    let totalHarga = 0;
  
    Object.values(resultById).forEach(({ totalQuantity, price }) => {
      totalHarga += totalQuantity * price;
    });
  
    return totalHarga;
  };
  useEffect(()=>{
    setValuespcs(totalbeda.toString());
  },[totalbeda])
  const TotalAmount = () => {
    let total = 0
    const nilain =calculateTotalQuantityAndBestPriceById(selectedProduct);
    const tot = calculateTotalHargaKeseluruhan(nilain)
    console.log('console',tot);
    console.log('console',nilain);
    console.log('consoleeko',selectedProduct);
    selectedProduct.forEach((product: {
      price: string; jumlah: string; diskon: string; defaulprice: string
    }) => {
      const disk = parseInt(product.diskon) || 0
      console.log(disk);
      total += subTotal(product.jumlah,disk.toString(),product.price, 'tidak')
    })
    totalbeda= total- tot
    console.log('consoleeko',totalbeda);

    
    return Number.isNaN(tot) ? 0 : tot;
  }

  const [paymentMethod, setPaymentMethod] = useState<string>('cash')
  const [paymentMethodsplit, setPaymentMethodsplit] = useState<string>('cash')
  const [defaultunit, setdefaultunit] = useState<any[]>([]); // Ensure it's an array
  const [paymentMethodsplit2, setPaymentMethodsplit2] = useState<string>('cash')
  const [split, setSplit] = useState<string>('')

  const handlePaymentMethodChange2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentMethodsplit(event.target.value)
  }
  const handlePaymentMethodChange3 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentMethodsplit2(event.target.value)
  }

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentMethod(event.target.value)
    const datasplit = event.target.value === 'split' ? 'masuk' : ''
    console.log(event.target.value)
    setSplit(datasplit)
    if (event.target.value !== 'cash' && event.target.value !== 'split') {
      setAmountPay(TotalAmount().toString())
    } else {
      setAmountPay('0')
      inputuang.current?.focus()
    }
  }
  const getNextIndex = (currentIndex: number): number => {
    let nextIndex = currentIndex + 1

    // Add your conditions here to skip certain indices
    while (nextIndex < inputRefs.current.length) {
      nextIndex++
    }

    // If nextIndex exceeds the array length, wrap around
    if (nextIndex >= inputRefs.current.length) {
      nextIndex = 0
    }

    return nextIndex
  }
  const getCodeByHargaTingkatId = (products: any, hargaTingkatId: any) => {
    for (let product of products) {
      const hargaTingkat = product.hargaatingkat.find(
        (item: { id: any }) => item.id === hargaTingkatId
      )
      if (hargaTingkat) {
        return product.code
      }
    }
    return null // Jika tidak ada produk dengan harga tingkatan yang sesuai
  }

  const calculateTotalPayment = () => {
    try {
      const parsedAmountPay = parseInt(amountPay.replace(/,/g, ''), 10) || 0
      const parsedAmountPay1 = parseInt(amountPay1.replace(/,/g, ''), 10) || 0
      const parsedAmountPay3 = parseInt(amountPay3.replace(/,/g, ''), 10) || 0

      const totalPayment = parsedAmountPay + parsedAmountPay1 + parsedAmountPay3

      // Update your state or perform any action with the totalPayment value
      // Example: setTotalPayment(totalPayment);

      return totalPayment
    } catch (error) {
      console.error('Error calculating total payment:', error)
      return 0 // Handle the error gracefully
    }
  }

  return (
    <div className='container'>
      <div>
        {showModal && (
          <div
            className='modal fade show'
            id='exampleModal'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
            style={{ display: 'block' }}
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h1 className='modal-title fs-5' id='exampleModalLabel'>
                    INPUT SALDO AWAL CASH DRAWER
                  </h1>
                </div>
                <div className='modal-body'>
                  <form>
                    <div className='mb-3'>
                      <label className='col-form-label'>Input Saldo Awal Cash Drawer</label>
                      <input
                        type='text'
                        ref={inputModalRef}
                        className='form-control'
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            closeModal()
                          }
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={closeModal}>
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='card-header row justify-content-between border-0 mt-4 mb-3 shadow-lg p-3 rounded bg-white'>
        <h2>Penjualan Produk</h2>
        <div className='col-12 col-md-2 mb-2 mt-4 d-flex align-items-center gap-5'>
          <div>
            <div className='d-flex align-items-center position-relative my-1 col-3'>
              <i className='fas fa-search position-absolute ms-3'></i>
              <input
                className='form-control w-200px ps-9'
                onKeyDown={(e) => {
                  console.log('key', e.key)

                  if (e.key == 'Tab') {
                    e.preventDefault()
                    setSearchValue('')
                    // Fokus pada input pertama di dalam tabel
                    const firstInputInTable = tableRef.current?.querySelector('input')
                    console.log('keysss', firstInputInTable)
                    if (firstInputInTable) {
                      firstInputInTable.focus()
                    } else {
                      dropdownRef.current?.focus()
                    }
                  } else if (e.key == 'ArrowUp') {
                    e.preventDefault()
                    setSelectedIndex((prevIndex) =>
                      prevIndex === null ? filteredData.length - 1 : Math.max(0, prevIndex - 1)
                    )
                  } else if (e.key == 'ArrowDown') {
                    e.preventDefault()
                    setSelectedIndex((prevIndex) =>
                      prevIndex === null ? 0 : Math.min(filteredData.length - 1, prevIndex + 1)
                    )
                  } else if (e.key == 'Enter') {
                    e.preventDefault()
                    if (filteredData.length === 1) {
                      console.log('dataprod4', filteredData[0]);
                      if (filteredData[0].stok <= 0) {
                        toast.error('Tidak dapat memilih produk,stok kosong')
                      } else {
                        handleSelectProduct(filteredData[0])
                      }
                    } else if (filteredData.length === 0) {
                      setSearchValue('')
                    } else {
                      if (filteredData[selectedIndex].stok <= 0) {
                        toast.error('Tidak dapat memilih produk,stok kosong')
                      } else {
                        handleSelectProduct(filteredData[selectedIndex])
                      }
                    }
                  } else if (e.key === 'Escape') {
                    e.preventDefault()
                    setSearchValue('')
                  }
                }}
                placeholder='Search'
                onFocus={() => {
                  if (inputModalRef2.current) {
                    inputModalRef2.current.style.borderColor = 'blue'
                  }
                }}
                onBlur={() => {
                  if (inputModalRef2.current) {
                    inputModalRef2.current.style.borderColor = ''
                  }
                }}
                data-kt-user-table-filter='search'
                value={searchValue}
                ref={inputModalRef2}
                onChange={(e) => {
                  handleSearchProduct(e)
                }}
              ></input>
            </div>
          </div>
          {/* <div className='col-3'>
            <select
              className='w-250px form-control'
              value={valuemember}
              onChange={(e) => {
                setValuemember(e.target.value)
                const memberId = parseInt(e.target.value, 10)
                const hargaUnit = member.find((memberData) => memberData.id === memberId)
                if (hargaUnit !== undefined) {
                  setValuespcs(hargaUnit.default_discount)
                } else {
                  setValuespcs('0')
                }
                // Lakukan pembaruan pada properti defaultunit
              }}
            >
              <option value=''>Pilih Pelanggaan</option>
              {member.map((option, index: number) => (
                <option key={index} value={option.id}>
                  {`${option.name}`}
                </option>
              ))}
            </select>
          </div> */}
        </div>
        {searchValue && (
          <div className='card-title'>
            <div
              className='search-results position-absolute bg-white border rounded'
              style={{ marginTop: '-15px', width: 'auto' }}
            >
              <ul className='list-group' style={{ marginBottom: '10px' }}>
                <div className='list-group-item d-flex justify-content-between align-items-center'>
                  <div className='col ms-auto' style={{ flex: 1, minWidth: 100, fontWeight: 'bold' }}>Nama</div>
                  <div className='col ms-auto' style={{ flex: 1, minWidth: 100, fontWeight: 'bold' }}>Keterangan</div>
                  <div className='col ms-auto' style={{ flex: 1, minWidth: 100, fontWeight: 'bold' }}>Kode</div>
                  <div className='ms-auto' style={{ flex: 0.5, minWidth: 50, fontWeight: 'bold' }}>Stok</div>
                </div>
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <a
                      key={index}
                      style={{
                        cursor: 'pointer',
                        color: index === selectedIndex ? 'white' : 'black',
                        backgroundColor: index === selectedIndex ? '#34759a' : ''
                      }}
                      className='list-group-item d-flex justify-content-between align-items-center'
                      onClick={() => {
                        inputModalRef2.current?.focus();
                        if (item.stok <= 0) {
                          toast.error('Tidak dapat memilih produk,stok kosong')
                        } else {
                          handleSelectProduct(item);
                        }
                      }}
                    >
                      <div className='col ms-auto' style={{ flex: 1, minWidth: 100 }}>{item.name}</div>
                      <div className='col ms-auto' style={{ flex: 1, minWidth: 100 }}>{item.note}</div>
                      <div className='col ms-auto' style={{ flex: 1, minWidth: 100 }}>{item.code}</div>
                      <div className='ms-auto' style={{ flex: 0.5, minWidth: 50 }}>{item.stok}</div>
                    </a>
                  ))
                ) : (
                  <h1 style={{ textAlign: 'center', margin: '20px 0' }}>Belum Ada Data Produk</h1>
                )}
              </ul>
            </div>
          </div>
        )}
        {/* {searchValue && (
          <div className='card-title'>
            <div
              className='search-results position-absolute bg-white border rounded '
              style={{marginTop: '-15px', width: 'auto'}}
            >
              <ul className='list-group' style={{marginBottom: '10px'}}>
                <div className='list-group-item d-flex justify-content-between align-items-center'>
                  <div className='col ms-auto' style={{flex: 1, minWidth: 100, fontWeight: 'bold'}}>
                    Nama
                  </div>
                  <div className='col ms-auto' style={{ flex: 1, minWidth: 100, fontWeight: 'bold' }}>
                    Keterangan
                  </div>
                  <div className='col ms-auto' style={{ flex: 1, minWidth: 100, fontWeight: 'bold' }}>
                    Kode
                  </div>
                  <div className='ms-auto' style={{flex: 0.5, minWidth: 50, fontWeight: 'bold'}}>
                    Stok
                  </div>
                </div>
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <a
                      key={item.id}
                      style={{
                        cursor: 'pointer',
                        color: index === selectedIndex ? 'white' : 'black', // Change text color here
                        backgroundColor: index === selectedIndex ? '#34759a' : '',
                      }}
                      className='list-group-item d-flex justify-content-between align-items-center'
                      onClick={() => {
                        inputModalRef2.current?.focus()
                        handleSelectProduct(item)
                      }}
                    >
                      <div className='col ms-auto' style={{flex: 1, minWidth: 100}}>
                        {item.name}
                      </div>
                      <div className='col ms-auto' style={{ flex: 1, minWidth: 100 }}>
                        {item.note}
                      </div>
                      <div className='col ms-auto' style={{ flex: 1, minWidth: 100 }}>
                        {item.code}{item.id}
                      </div>
                      <div className='ms-auto' style={{flex: 0.5, minWidth: 50}}>
                        {`${item.stok} `}
                      </div>
                    </a>
                  ))
                ) : (
                  <h1 style={{textAlign: 'center', margin: '20px 0'}}>Belum Ada Data Produk</h1>
                )}
              </ul>
            </div>
          </div>
        )} */}
      </div>

      <Pembayaran
        showPayment={showPayment}
        onClosePayment={handleClosePayment}
        onClosePayment2={handleClosePayment2}
        onTahanPayment={handlertahan}
        datapayment={selectedProduct}
        totalQty={totalQty()}
        dataToko={detailToko}
        totamount={TotalAmount()}
        paymentMethod={paymentMethod}
        paymentMethod1={paymentMethodsplit}
        paymentMethod2={paymentMethodsplit2}
        amountPay={parseInt(amountPay.replace(/,/g, ''), 10)}
        amountPay1={parseInt(amountPay1.replace(/,/g, ''), 10)}
        amountPay2={parseInt(amountPay3.replace(/,/g, ''), 10)}
        bayar={calculateTotalPayment()}
        idmember={valuemember}
        defaultdis={totalbeda}
        trans_id={transid}
      />
      <TahanTransaksi
        showTahanTransaksi={showTahanTransaksi}
        onCloseTahanTransaksi={handleCloseTahanTransaksi}
        ongetData={datashowdata}
        onDelte={(id: number) => handledeletetrraans(id)}
        datatransakksi={tahanTransaksi}
        idtrans={setidTransaksi}
      />
      <div className='row container-fluid border-0 mt-4 mb-3 shadow-lg bg-white rounded'>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table
            ref={tableRef}
            className='table table-striped table-row-bordered table-row-white-100 align-middle'
          >
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-bolder'>
                <th className='min-w-10px'>Nama Produk</th>
                <th className='min-w-200px'>Kode</th>
                <th className='min-w-125px'>Kwantitas</th>
                {/* <th className='min-w-125px'>Satuan</th> */}
                {selectedProduct.length > 0 && <th className='min-w-125px'>Satuan</th>}
                <th className='min-w-125px'>Harga</th>
                <th className='min-w-125px'>Diskon</th>
                <th className='min-w-140px'>Subtotal</th>
                <th className='min-w-80px'>Aksi</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {selectedProduct.map((val: any, index: number) => (
                <tr key={val.id}>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bold text-hover-primary fs-6 ms-2'
                    >
                      {val.name}
                    </a>
                  </td>
                  <td>
                    <p className='text-dark fw-bold text-hover-primary d-block mb-4 fs-6'>
                      {val.code} - {val.note}
                    </p>
                  </td>
                  <td  >
                    <div
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      <input
                        className='form-control'
                        type='number'
                        ref={(ref) => {
                          if (ref) {
                            const inputRef = { current: ref } as React.RefObject<HTMLInputElement>
                            inputRefs.current[index] = inputRef
                          }
                        }}
                        value={
                          productQty[`${val.id}-${val.code}`]
                            ? productQty[`${val.id}-${val.code}`]
                            : val.jumlah
                        }
                        onChange={(e) => {
                          const hargaatingkat = selectedProduct[index].hargaatingkat;
                          const defaultUnitDetails = hargaatingkat.find((unit: { name: any }) => unit.name === val.defaultunit);
                          const nilai = parseInt(defaultUnitDetails.conversion) * parseInt(e.target.value);

                          if (nilai > val.stok) {
                            toast.error('Stok tidak cukup')
                          } else {
                            handleChangeQty(val.id, val.code, e.target.value)
                          }
                        }
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault()
                            const currentInputIndex = inputRefs.current.findIndex(
                              (ref) => ref.current === e.currentTarget
                            )
                            const isLastInput = currentInputIndex === inputRefs.current.length - 1
                            const nextIndex = getNextIndex(currentInputIndex)
                            const cur = isLastInput === true ? 0 : currentInputIndex + 1
                            console.log('current', cur)

                            inputdiskonRefs.current[currentInputIndex].current?.focus()
                          } else if (e.key === 'Enter') {
                            dropdownRef.current?.focus() // Pindah ke dropdown
                          } else if (e.shiftKey && e.key === 'Backspace') {
                            e.preventDefault()
                            const currentInputIndex = inputRefs.current.findIndex(
                              (ref) => ref.current === e.currentTarget
                            )
                            const isLastInput = currentInputIndex === inputRefs.current.length - 1
                            const nextIndex = getNextIndex(currentInputIndex)
                            const cur =
                              isLastInput === true
                                ? 0
                                : currentInputIndex - 1 == -1
                                  ? 0
                                  : currentInputIndex - 1
                            console.log(cur)
                            if (selectedProduct.length === 0 || selectedProduct.length === 1) {
                              handleRemoveItem(val.id, val.code)
                              inputModalRef2.current?.focus()
                            } else if (selectedProduct.length > 1) {
                              if (isLastInput) {
                                handleRemoveItem(val.id, val.code)
                                inputRefs.current[cur].current?.focus() // Pindah ke input berikutnya
                              } else {
                                handleRemoveItem(val.id, val.code)
                                inputRefs.current[cur].current?.focus() // Pindah ke input berikutnya
                              }
                            }
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <select
                      className='form-select'
                      id='satuanSelect'
                      value={JSON.stringify(val.defaultdropddown)}
                      onChange={(e) => {
                        const selectedItems = JSON.parse(e.target.value); // Mengambil id dan name dari objek yang dipilih
                        const selectedItem: { id: string; name: string } = JSON.parse(e.target.value) // Mengambil id dan name dari objek yang dipilih
                        console.log('items', val.defaultdropddown);
                        console.log('items', selectedItems);
                        const updatedSelectedProduct = selectedProduct.map(
                          (product: {
                            hargaatingkat: any
                            id: string
                            code: string
                            jumlah: string
                          }) => {
                            if (product.code === val.code) {
                              const filteredProduct = {
                                ...product,
                                defaultunit: selectedItem.name,
                                hargaatingkat: product.hargaatingkat.filter(
                                  (item: { id: any }) => item.id === selectedItem.id
                                ),
                              }
                              const quantity = parseInt(filteredProduct.jumlah)
                              const hargaUnit = getSelectedRange(quantity, filteredProduct)

                              const unitPrice = calculateUnitPrice(hargaUnit, quantity)

                              const subtotal = unitPrice * quantity
                              return {
                                ...product,
                                price: hargaUnit.price,
                                defaultunit: selectedItem.name,
                                defaultprice: unitPrice,
                                defaultdropddown:selectedItems,
                                unit_id: filteredProduct.hargaatingkat[0].id
                              }
                            }
                          }
                        )
                        const filteredProductsku = updatedSelectedProduct.filter((product: any) => product !== null && product !== undefined);

                        const updatedProducts = selectedProduct.map((product: { id: any; code: any }) => {
                          if (!product) return product;

                          const matchingProduct = filteredProductsku.find(
                            (updatedProduct: { id: any; code: any }) => {
                              if (!updatedProduct) return false;
                              return updatedProduct.id === product.id && updatedProduct.code === product.code;
                            }
                          );

                          return matchingProduct ? { ...product, ...matchingProduct } : product;
                        });

                        const defaultUnit = updatedProducts[0].defaultunit;
                        const hargaatingkat = updatedProducts[0].hargaatingkat;

                        const foundItem = hargaatingkat.find((item: { name: any }) => item.name === defaultUnit);
                        console.log(foundItem, 'item');
                        console.log(val.stok, 'item');
                        if (val.stok < foundItem.conversion) {
                          toast.error('Stok Tidak Cukup')
                        } else {
                          setSelectedProduct(updatedProducts);
                          const productKey = `${val.id}-${val.code}`
                          const updatedProductQty = { ...productQty, [productKey]: 1 }
                          setProductQty(updatedProductQty)
                          // setdefaultunit(selectedItem)
                        }
                      }}
                    >
                      {val.hargaatingkat.map((items: any, index: number) => (
                        <option key={index} value={JSON.stringify(items)}>
                          {items.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <span>{convertIDR(val.price)}</span>
                  </td>
                  <td className='d-flex justify-content-between align-items-center'>
                    <input
                      className='form-control'
                      type='number'
                      ref={(ref) => {
                        if (ref) {
                          const inputRef = { current: ref } as React.RefObject<HTMLInputElement>
                          inputdiskonRefs.current[index] = inputRef
                        }
                      }}
                      value={val.diskon}
                      onKeyDown={(e) => {
                        if (e.key === 'Tab') {
                          e.preventDefault()
                          const currentInputIndex = inputdiskonRefs.current.findIndex(
                            (ref) => ref.current === e.currentTarget
                          )
                          const isLastInput =
                            currentInputIndex === inputdiskonRefs.current.length - 1
                          const nextIndex = getNextIndex(currentInputIndex)
                          const cur = isLastInput === true ? 0 : currentInputIndex + 1
                          inputdiskonRefs2.current[currentInputIndex].current?.focus()
                        } else if (e.key === 'Enter') {
                          dropdownRef.current?.focus() // Pindah ke dropdown
                        } else if (e.shiftKey && e.key === 'Backspace') {
                          e.preventDefault()
                          const currentInputIndex = inputdiskonRefs.current.findIndex(
                            (ref) => ref.current === e.currentTarget
                          )
                          const isLastInput =
                            currentInputIndex === inputdiskonRefs.current.length - 1
                          const nextIndex = getNextIndex(currentInputIndex)
                          const cur =
                            isLastInput === true
                              ? 0
                              : currentInputIndex - 1 == -1
                                ? 0
                                : currentInputIndex - 1
                          console.log(cur)
                          if (selectedProduct.length === 0 || selectedProduct.length === 1) {
                            handleRemoveItem(val.id, val.code)
                            inputModalRef2.current?.focus()
                          } else if (selectedProduct.length > 1) {
                            if (isLastInput) {
                              handleRemoveItem(val.id, val.code)
                              inputRefs.current[cur].current?.focus() // Pindah ke input berikutnya
                            } else {
                              handleRemoveItem(val.id, val.code)
                              inputRefs.current[cur].current?.focus() // Pindah ke input berikutnya
                            }
                          }
                        }
                      }}
                      onChange={(e) => handlediskon(val.id, e.target.value, 'present', val.code)}
                    />
                    <span className='percent-text'>%</span>

                    <input
                      className='form-control'
                      type='number'
                      value={val.diskonprice}
                      ref={(ref) => {
                        if (ref) {
                          const inputRef = { current: ref } as React.RefObject<HTMLInputElement>
                          inputdiskonRefs2.current[index] = inputRef
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Tab') {
                          e.preventDefault()
                          const currentInputIndex = inputdiskonRefs2.current.findIndex(
                            (ref) => ref.current === e.currentTarget
                          )
                          const isLastInput =
                            currentInputIndex === inputdiskonRefs2.current.length - 1
                          const nextIndex = getNextIndex(currentInputIndex)
                          const cur = isLastInput === true ? 0 : currentInputIndex + 1
                          console.log('daataalast', isLastInput)

                          if (selectedProduct.length > 0) {
                            console.log('cek')
                            // Jika lebih dari satu input dan ini input terakhir
                            if (isLastInput === true || selectedProduct.length === 1) {
                              console.log('cek3')
                              dropdownRef.current?.focus() // Pindah ke inputModalRef
                            } else {
                              console.log('cek4')
                              inputRefs.current[cur].current?.focus() // Pindah ke input berikutnya
                            }
                          } else {
                            console.log('cek5')
                            // Jika selectedProduct.length sama dengan 0
                            dropdownRef.current?.focus() // Pindah ke dropdown
                          }
                        } else if (e.key === 'Enter') {
                          dropdownRef.current?.focus() // Pindah ke dropdown
                        } else if (e.shiftKey && e.key === 'Backspace') {
                          e.preventDefault()
                          const currentInputIndex = inputdiskonRefs2.current.findIndex(
                            (ref) => ref.current === e.currentTarget
                          )
                          const isLastInput =
                            currentInputIndex === inputdiskonRefs2.current.length - 1
                          const nextIndex = getNextIndex(currentInputIndex)
                          const cur =
                            isLastInput === true
                              ? 0
                              : currentInputIndex - 1 == -1
                                ? 0
                                : currentInputIndex - 1
                          console.log(cur)
                          if (selectedProduct.length === 0 || selectedProduct.length === 1) {
                            handleRemoveItem(val.id, val.code)
                            inputModalRef2.current?.focus()
                          } else if (selectedProduct.length > 1) {
                            if (isLastInput) {
                              handleRemoveItem(val.id, val.code)
                              inputRefs.current[cur].current?.focus() // Pindah ke input berikutnya
                            } else {
                              handleRemoveItem(val.id, val.code)
                              inputRefs.current[cur].current?.focus() // Pindah ke input berikutnya
                            }
                          }
                        }
                      }}
                      onChange={(e) => handlediskon(val.id, e.target.value, 'now', val.code)}
                    />
                  </td>
                  <td>
                    <span>{convertIDR(subTotal(val.jumlah, val.diskon, val.price, 'tidak'))}</span>
                  </td>
                  <td>
                    <button
                      className='btn btn-white btn-sm'
                      onClick={() => handleRemoveItem(val.id, val.code)}
                    >
                      <i className='bi bi-x-lg'></i>
                    </button>
                  </td>
                </tr>
              ))}
              {totalbeda > 1 && (
                <tr>
                  <td colSpan={3}></td>
                  <td colSpan={2}>
                    <h1>Potongan Harga</h1>
                  </td>
                  <td colSpan={2}>
                    <h1>{convertIDR(totalbeda)}</h1>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={3}></td>
                <td colSpan={2}>
                  <h1>Grand Total</h1>
                </td>
                <td colSpan={2}>
                  <h1>{convertIDR(TotalAmount())}</h1>
                </td>
              </tr>
              <tr>
                <td colSpan={3}></td>
                <td colSpan={2}>
                  <h2>Tipe Bayar</h2>
                </td>
                <td colSpan={2}>
                  <select
                    ref={dropdownRef}
                    className=' form-control-solid-bg form-control'
                    value={paymentMethod}
                    onFocus={() => {
                      if (dropdownRef.current) {
                        dropdownRef.current.style.borderColor = 'blue'
                      }
                    }}
                    onBlur={() => {
                      if (dropdownRef.current) {
                        dropdownRef.current.style.borderColor = ''
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        if (paymentMethod === 'cash') {
                          setSplit('')
                          inputuang.current?.focus()
                        }
                        if (paymentMethod === 'split') {
                          setSplit('masuk')
                        } else {
                          setSplit('')
                          handlePaymentClick()
                        }
                      }
                    }}
                    onChange={(e) => {
                      handlePaymentMethodChange(e)
                    }}
                  >
                    <option value='cash'>Tunai</option>
                    <option value='qris'>QRIS</option>
                    <option value='transfer'>EDC / Transfer</option>
                    <option value='split'>Split</option>
                  </select>
                </td>
              </tr>
              {paymentMethod === 'cash' && (
                <tr>
                  <td colSpan={3}></td>

                  <td colSpan={2}>
                    <h1>Bayar</h1>
                  </td>
                  <td colSpan={2}>
                    <input
                      className='form-control'
                      type='text'
                      value={amountPay}
                      ref={inputuang}
                      onFocus={() => {
                        if (inputuang.current) {
                          inputuang.current.style.borderColor = 'blue'
                        }
                      }}
                      onBlur={() => {
                        if (inputuang.current) {
                          inputuang.current.style.borderColor = ''
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          if (
                            parseInt(amountPay.replace(/,/g, ''), 10) !== 0 &&
                            parseInt(amountPay.replace(/,/g, ''), 10) >= TotalAmount()
                          ) {
                            handlePaymentClick()
                          } else {
                            toast.error('Harap Periksa Data Anda ' + paymentMethod)
                            console.log('total', TotalAmount())
                            console.log('total', parseInt(amountPay.replace(/,/g, ''), 10))
                          }
                        } else if (e.key === 'Tab') {
                          e.preventDefault()
                          historiRef.current?.focus()
                        }
                      }}
                      onChange={handleInputChange2}
                    />
                    {/* <h6 className='mt-2'>Rp {amountPay}</h6> */}
                  </td>
                </tr>
              )}
              {split !== '' && (
                <>
                  <tr>
                    <td colSpan={3}></td>
                    <td colSpan={2}>
                      <h2>Tipe Bayars Split 1</h2>
                    </td>
                    <td colSpan={2}>
                      <select
                        ref={dropdownRef1}
                        className=' form-control-solid-bg form-control'
                        value={paymentMethodsplit}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            inputuang2.current?.focus()
                          }
                        }}
                        onChange={(e) => {
                          handlePaymentMethodChange2(e)
                        }}
                      >
                        <option value='cash'>Tunai</option>
                        <option value='qris'>QRIS</option>
                        <option value='transfer'>EDC / Transfer</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}></td>

                    <td colSpan={2}>
                      <h1>Bayar</h1>
                    </td>
                    <td colSpan={2}>
                      <input
                        className='form-control'
                        type='text'
                        value={amountPay1}
                        ref={inputuang2}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            const dataa =
                              parseInt(amountPay3.replace(/,/g, ''), 10) +
                              parseInt(amountPay1.replace(/,/g, ''), 10)
                            console.log('totamount', dataa)

                            if (TotalAmount() - 1 < dataa) {
                              handlePaymentClick()
                            } else {
                              toast.error('Harap Periksa Data Anda ')
                              console.log('total', TotalAmount())
                              console.log('total', parseInt(amountPay1.replace(/,/g, ''), 10))
                            }
                          } else if (e.key === 'Tab') {
                            e.preventDefault()
                            dropdownRef2.current?.focus()
                          }
                        }}
                        onChange={handleInputChange3}
                      />
                      {/* <h6 className='mt-2'>Rp {amountPay}</h6> */}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}></td>
                    <td colSpan={2}>
                      <h2>Tipe Bayars Split 2</h2>
                    </td>
                    <td colSpan={2}>
                      <select
                        ref={dropdownRef2}
                        className=' form-control-solid-bg form-control'
                        value={paymentMethodsplit2}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            inputuang3.current?.focus()
                          }
                        }}
                        onChange={(e) => {
                          handlePaymentMethodChange3(e)
                        }}
                      >
                        <option value='cash'>Tunai</option>
                        <option value='qris'>QRIS</option>
                        <option value='transfer'>EDC / Transfer</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}></td>

                    <td colSpan={2}>
                      <h1>Bayar</h1>
                    </td>
                    <td colSpan={2}>
                      <input
                        className='form-control'
                        type='text'
                        value={amountPay3}
                        ref={inputuang3}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            const dataa =
                              parseInt(amountPay3.replace(/,/g, ''), 10) +
                              parseInt(amountPay1.replace(/,/g, ''), 10)
                            console.log('totamount', dataa)

                            if (TotalAmount() - 1 < dataa) {
                              handlePaymentClick()
                            } else {
                              toast.error('Harap Periksa Data Anda ')
                              console.log('total', TotalAmount())
                              console.log('total', parseInt(amountPay3.replace(/,/g, ''), 10))
                            }
                          } else if (e.key === 'Tab') {
                            e.preventDefault()
                            historiRef.current?.focus()
                          }
                        }}
                        onChange={handleInputChange4}
                      />
                      {/* <h6 className='mt-2'>Rp {amountPay}</h6> */}
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td colSpan={3}></td>
                <td colSpan={2}>
                  <h1>Kembali</h1>
                </td>
                <td colSpan={2}>
                  {paymentMethod === 'cash' ? (
                    <h1>{convertIDR(parseInt(amountPay.replace(/,/g, ''), 10) - TotalAmount())}</h1>
                  ) : split !== '' ? (
                    <h1>
                      {convertIDR(
                        parseInt(amountPay3.replace(/,/g, ''), 10) +
                        parseInt(amountPay1.replace(/,/g, ''), 10) -
                        TotalAmount()
                      )}
                    </h1>
                  ) : (
                    <h1>Rp 0</h1>
                  )}
                </td>
              </tr>

              <tr>
                <td className='col-10 d-flex'>
                  <button
                    ref={historiRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Tab') {
                        e.preventDefault()
                        tahanRef.current?.focus()
                      }
                    }}
                    className='col-10 btn btn-info btn-lg text-wrap mt-2 me-2'
                    onClick={handleTahanTransaksiClick}
                  >
                    Histori Transaksi
                  </button>
                  <button
                    ref={tahanRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Tab') {
                        e.preventDefault()
                        inputModalRef2.current?.focus()
                      }
                    }}
                    className='col-10 btn btn-secondary btn-lg text-wrap mt-2 me-2'
                    onClick={handlertahan}
                  >
                    <span>Tahan Transaksi</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ToastContainer position='top-right' autoClose={2000} />
        </div>
      </div>
    </div>
  )
}

export default POSPage
function TotalAmount() {
  throw new Error('Function not implemented.')
}
