import React, {useEffect, useState} from 'react'
import usePageTitle from '../../functions/global/usePageTitle'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../_metronic/helpers'
import axios from 'axios'
import {showLapStok} from '../../functions/global/api'
import {convertDate, convertIDR} from '../../functions/global'
import * as XLSX from 'xlsx'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Empty, Pagination} from 'antd'

const API_URL = process.env.REACT_APP_API_URL

const HistoryReturnPage = () => {
  usePageTitle('Laporan Stok')

  const [products, setProducts] = useState<any[]>([])
  const [showProdukConfirmation, setShowProdukConfirmation] = useState(false)
  const [produkToProduk, setProdukToProduk] = useState<number | null>(null)
  const [arrangeBy, setArrangeBy] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [newProduct, setNewProduct] = useState({
    product_name: '',
    product_code: '',
    category_id: '',
    faktur_date_from: '',
    faktur_date_to: '',
  })

  const handleSearch = (eventOrPageNumber: React.MouseEvent<HTMLButtonElement> | number) => {
    if (typeof eventOrPageNumber === 'number') {
      const pageNumber = eventOrPageNumber

      const searchParams = {
        product_name: newProduct.product_name,
        start_date: newProduct.faktur_date_from,
        end_date: newProduct.faktur_date_to,
        arrange_by: arrangeBy,
        sort_by: sortBy,
      }

      axios
        .get(
          `${API_URL}/stock-opname?page=&product_name=&start_date=&end_date=&arrange_by=&sort_by=`,
          {
            params: {...searchParams, page: pageNumber},
          }
        )
        .then((response) => {
          setProducts(response.data.data.data)
          setCurrentPage(pageNumber)
          setTotalPages(response.data.data.total_pages)
        })
        .catch((error) => {
          console.error('Error searching products:', error)
        })
    } else {
    }
  }

  const showData = () => {
    showLapStok().then((data) => {
      console.log(data)
      setProducts(data)
    })
  }

  useEffect(() => {
    showData()
  }, [])

  const exportToExcel = () => {
    const dataToExport = products.map((product, index) => ({
      // Customize this based on your data structure
      No: index + 1,
      Kode: product.product_code,
      'Tanggal Faktur': convertDate(product.faktur_date),
      'Nama Produk': product.product_name,
      'Stok Saat Ini': product.stock,
      Harga: convertIDR(product.hpp_price),
      'Sub Total': convertIDR(product.sub_total),
    }))

    const ws = XLSX.utils.json_to_sheet(dataToExport)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')

    // Save the Excel file
    XLSX.writeFile(wb, 'exported_data.xlsx')
  }

  return (
    <>
      <KTCard className='mb-10'>
        <div className='card card-flush pt-3 mb-6 mb-lg-6'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Filter</h3>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row fw-bold'>
              <div className='col-3'>
                <label className='mb-2'>Nama Produk</label>
                <input
                  placeholder='Masukkan nama produk'
                  className='form-control'
                  type='text'
                  name='search'
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch(1)
                    }
                  }}
                  onChange={(e) => setNewProduct({...newProduct, product_name: e.target.value})}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Dari</label>
                <input
                  type='date'
                  placeholder='dd/mm/yyyy'
                  className='form-control'
                  name='fromDate'
                  onChange={(e) => setNewProduct({...newProduct, faktur_date_from: e.target.value})}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Sampai</label>
                <input
                  type='date'
                  placeholder='dd/mm/yyyy'
                  className='form-control'
                  name='toDate'
                  onChange={(e) => setNewProduct({...newProduct, faktur_date_to: e.target.value})}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Urutkan Berdasarkan</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={arrangeBy}
                  onChange={(e) => setArrangeBy(e.target.value)}
                  required
                >
                  <option value='tanggal'>Tanggal Input</option>
                  <option value='category_id'>Kategori</option>
                  <option value='product_code'>Kode Produk</option>
                  <option value='product_name'>Nama Produk</option>
                </select>
              </div>
              <div className='col-3'>
                <label className='mb-2'>Urutan</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={sortBy}
                  onChange={(e) => {
                    if (arrangeBy) {
                      setSortBy(e.target.value)
                    } else {
                      toast.error(
                        'Tolong isikan "Urutan" dahulu sebelum memilih "Urutkan Berdasarkan".',
                        {
                          position: toast.POSITION.TOP_RIGHT,
                        }
                      )
                    }
                  }}
                  required
                >
                  <option value='asc'>A-Z</option>
                  <option value='desc'>Z-A</option>
                </select>
              </div>
              <div className='col-1 mt-8 me-2'>
                <Button onClick={() => handleSearch(1)}>Cari</Button>
              </div>
            </div>
          </div>
        </div>
      </KTCard>

      <KTCard>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Laporan Stok</h3>
          </div>
        </div>
        <div className='separator separator mb-4'></div>

        {/* begin::Table container */}
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <div className='d-flex align-items-center position-relative mb-6'>
              <Button onClick={exportToExcel} className='bi bi-printer'>
                <span> Ekspor </span>{' '}
              </Button>
            </div>
            {/* begin::Table */}
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center'>
                  <th className='ps-4 rounded-start'>No</th>
                  <th>Tanggal Faktur</th>
                  <th>Nama Produk</th>
                  <th>Stok Saat Ini</th>
                  <th>HPP</th>
                  <th className='pe-4 rounded-end'>Sub Total</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((product, index) => (
                    <tr key={index} className='text-center'>
                      <td align='center'>{index + 1}</td>
                      {/* <td>
                        <button type='button' className='btn btn-text'>
                          <a
                            onClick={() => openProdukConfirmation(product.id)}
                            className='text-dark fw-bold text-hover-primary fs-6'
                          >
                            {product.product_code}
                          </a>
                        </button>
                      </td> */}
                      {/* <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {Array.isArray(product.product_codes)
                            ? product.product_codes
                                .map((codeItem: {code: any}) => codeItem.code)
                                .join(', ')
                            : 'No codes available'}
                        </div>
                      </td> */}
                      <td>
                        <strong className='text-dark fw-bold  d-block mb-1 fs-6'>
                          {convertDate(product.faktur_date)}
                        </strong>
                      </td>
                      <td>
                        <strong className='text-dark fw-bold d-block mb-1 fs-6'>
                          {product.product_name}
                        </strong>
                      </td>
                      <td align='center' className='text-dark fw-bold  fs-6'>
                        {product.stock}
                      </td>
                      <td className='text-dark fw-bold fs-6'>
                        <span>{convertIDR(product.hpp_price)}</span>
                      </td>
                      <td className='text-dark fw-bold fs-6'>
                        <span>{convertIDR(product.sub_total)}</span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className='text-center'>
                      <Empty description='Data tidak tersedia' />
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* pagination */}
          <div className='row mb-6 mt-6'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <Pagination
                current={currentPage}
                total={totalPages * 10}
                onChange={(page) => handleSearch(page)}
                showSizeChanger={false}
                pageSize={10}
              />
            </div>
          </div>
          {/* end::Table container */}
        </div>
        {/* modal */}
        {showProdukConfirmation && produkToProduk !== null && (
          <Modal
            show={showProdukConfirmation}
            onHide={() => setShowProdukConfirmation(false)}
            size='lg'
          >
            <Modal.Header closeButton>
              <Modal.Title>Produk Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className='mb-2'>
                  <label className='col-form-label'>Nama Produk:</label>
                  <input
                    type='text'
                    className='form-control mb-4'
                    value={
                      products.find((product) => product.id === produkToProduk)?.product_name || ''
                    }
                    disabled
                  />
                  <label className='col-form-label'>Nomor Faktur:</label>
                  <input
                    type='text'
                    className='form-control mb-4'
                    value={
                      products.find((product) => product.id === produkToProduk)?.faktur_number || ''
                    }
                    disabled
                  />
                  <label className='col-form-label'>Tanggal Faktur:</label>
                  <input
                    type='text'
                    className='form-control mb-4'
                    value={
                      convertDate(
                        products.find((product) => product.id === produkToProduk)?.faktur_date
                      ) || ''
                    }
                    disabled
                  />
                </div>
              </form>
            </Modal.Body>
          </Modal>
        )}
        <ToastContainer position='top-right' autoClose={2000} />
      </KTCard>
    </>
  )
}

export default HistoryReturnPage
