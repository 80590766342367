import React, { useEffect, useState } from 'react'
import usePageTitle from '../../functions/global/usePageTitle';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { KTCard } from '../../../_metronic/helpers';
import { useAuth } from '../auth';
import { GetRecap, getToko, postRecap } from '../../functions/global/api';
import { Interface } from 'readline';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import logo from '../../../_metronic/assets/img/logo3.png'
import 'jspdf-autotable';


interface CashierData {
    cashier_id: number;
    cashier_name: string;
    discount_cash: number;
    discount_qris: number;
    discount_total: number;
    discount_transfer: number;
    end_date: string;
    end_time: string;
    nota_total: number;
    retur_nominal: number;
    retur_total: number;
    start_date: string;
    start_time: string;
    total_cash: number;
    total_qris: number;
    total_transaction: number;
    total_transfer: number;
}


const ShiftRecapPage = () => {
    usePageTitle('Rekap Shift');

    const { currentUser, logout } = useAuth()
    const [data, setdata] = useState<CashierData | null | any>([]);
    const [currentTime, setCurrentTime] = useState<string | undefined>(undefined);
    const [toko,setToko] = useState<any>([])

    useEffect(() => {
        getdatarekap();
    }, []);
    const getdatarekap = async () => {
        const res = await GetRecap();
        const responseku = await getToko()
        setToko(responseku.data)
        setdata(res.data)
        const dat = localStorage.getItem('drawer');
        if (dat != null) {
            setCurrentTime(dat)
        }
    }
    const handleout = async () => {
        const res = await postRecap(data);
        if (res.status === 'success') {
            handlePrintReceipt();
            logout();
            console.log('res', res.data);

        }
    }
    function getCurrentTime() {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }
    const handlePrintReceipt = () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [48, 210],
        });
    
        const pageWidth = pdf.internal.pageSize.getWidth();
        const logoWidth = 40; // Lebar gambar yang sesuai
        const logoX = (pageWidth - logoWidth) / 2; // Menempatkan gambar di tengah
        const logoY = 10; 
        let yOffset = 26; // Posisi awal Y untuk teks alamat
    
        // Mengatur rasio aspek logo
        const img = new Image();
        img.src = logo;
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            const logoHeight = logoWidth / aspectRatio; // Menjaga rasio aspek asli
    
            // Tambahkan logo
            pdf.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);
    
            pdf.setFontSize(8);
    
            // Teks nama toko
            pdf.text(`${toko.name}`, pageWidth / 2, yOffset, { align: 'center' });
            yOffset += 3.5; // Sesuaikan nilai ini untuk menghindari tumpang tindih
    
            // Pisahkan alamat menjadi beberapa baris dan pusatkan setiap baris
            const addressLines = pdf.splitTextToSize(toko.address, pageWidth - 10);
            addressLines.forEach((line: string | string[]) => {
                pdf.text(line, pageWidth / 2, yOffset, { align: 'center' });
                yOffset += 2.5; // Sesuaikan jarak antar baris jika diperlukan
            });
    
            // Nomor telepon di bawah alamat
            yOffset += 1.5; // Sesuaikan jarak antara alamat dan nomor telepon
            pdf.text(`${toko.phone}`, pageWidth / 2, yOffset, { align: 'center' });
            yOffset += 3; // Sesuaikan jarak antara nomor telepon dan teks berikutnya
    
            // Tambahkan teks "Rekap Shift" dan data lainnya
            yOffset += 3; // Sesuaikan jarak sebelum "Rekap Shift"
            pdf.text('Rekap Shift', 2, yOffset);
            const columnWidth = pageWidth - 4; // Lebar kolom
            const startX = 2; // Mulai dari posisi x: 2
            yOffset += 3; // Mulai dari posisi y setelah "Rekap Shift"
    
            // Fungsi untuk menambahkan teks dengan spasi di sekitarnya
            const addJustifiedText = (text: string) => {
                const splitText = pdf.splitTextToSize(text, columnWidth);
                pdf.text(splitText, startX, yOffset);
                yOffset += pdf.getTextDimensions(text).h + 3;
            };
    
            // Tambahkan teks dengan spasi di sekitarnya
            addJustifiedText(`Kasir                     : ${data?.cashier_name}`);
            addJustifiedText(`Modal Awal          : Rp ${currentTime}`);
            addJustifiedText(`Tanggal                : ${data?.start_date}`);
            addJustifiedText(`Jam Mulai             : ${data?.start_time}`);
            addJustifiedText(`Jam Berakhir        : ${data?.end_time}`);
            addJustifiedText(`Transaksi Cash    : Rp ${data?.total_cash?.toLocaleString() || 0}`);
            addJustifiedText(`Transaksi QRS     : Rp ${data?.total_qris?.toLocaleString() || 0}`);
            addJustifiedText(`Transaksi EDC     : Rp ${data?.total_transfer?.toLocaleString() || 0}`);
            addJustifiedText(`Total Diskon         : Rp ${data?.discount_total?.toLocaleString() || 0}`);
            addJustifiedText(`Return                  : ${data?.retur_nominal}`);
            addJustifiedText(`Total Transaksi    : Rp ${data?.total_transaction?.toLocaleString() || 0}`);
            addJustifiedText(`Total Nota            : ${data?.nota_total}`);
    
            const dashLength = 1;
            const gapLength = 1;
            let currentX = 1;
            const endX = pageWidth - 1;
            let currentY = yOffset + 2;
    
            pdf.setFontSize(6);
    
            // Tambahkan teks footer
            pdf.text('POS System Powered by ProjoTech', pageWidth / 2, currentY + 60, { align: 'center' });
            pdf.text('https://projotech.id/', pageWidth / 2, currentY + 63, { align: 'center' });
    
            // Membuat file PDF dan membuka jendela baru untuk mencetak
            const blob = pdf.output('blob');
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, '_blank');
            if (newWindow) {
                newWindow.onload = () => {
                    newWindow.print();
                };
            } else {
                alert('Cannot open a new window. Make sure popups are not blocked.');
            }
        };
    };
    
    
    return (
        <>
            <KTCard>
                <div className='container px-12 pt-6 '>
                    <h2 className='text-center mb-8'>Rekap Shift</h2>
                    <div className="d-flex justify-content-center mb-6">
                        <Form style={{
                            width: "720px"
                        }}>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Nama Kasir</Form.Label>
                                <Col sm="8">
                                    <Form.Control type="text" disabled value={data?.cashier_name || ''}

                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Modal Awal </Form.Label>
                                <Col sm="8">
                                    <Form.Control type="text" disabled value={"Rp " + currentTime || "Rp " + '0'}

                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formGroupEmail">
                                <Form.Label column sm="4" className="fw-bold">Tanggal</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={data?.start_date} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Jam Mulai</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={data?.start_time} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" className="fw-bold">Jam Berakhir</Form.Label>
                                <Col sm="8">
                                    <Form.Control type="text" value={data?.end_time} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Transaksi Cash</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={"Rp " + (data?.total_cash?.toLocaleString() || 0)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Transaksi QRS</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={"Rp " + (data?.total_qris?.toLocaleString() || 0)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Transaksi EDC / Transfer</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={"Rp " + (data?.total_transfer?.toLocaleString() || 0)} />
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Diskon Transaksi</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={"Rp " + (data?.discount_transaction?.toLocaleString() || 0)} />
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Total Diskon</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={"Rp " + (data?.discount_total?.toLocaleString() || 0)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Return</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={data?.retur_nominal} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Total Transaksi</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled value={"Rp " + (currentTime ? ((data?.total_transaction ?? 0)).toLocaleString() : '0')} type="text" />
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Cash Drawer</Form.Label>
                                <Col sm="8">
                                    <Form.Control type="number" placeholder="Input here..." />
                                </Col>
                            </Form.Group> */}
                            {/* <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Selisih Cash Drawer</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="Rp.100.000,00" />
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Total Nota</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={data?.nota_total} />
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Saldo Awal</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="Rp.200.000,00" />
                                </Col>
                            </Form.Group> */}
                            <div className="d-flex justify-content-center mt-8">
                                <Button variant="primary" size="lg" onClick={handleout}>
                                    Simpan & Log Out
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <ToastContainer position="top-right" autoClose={2000} />
            </KTCard>
        </>

    )
}

export default ShiftRecapPage