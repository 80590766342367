import React from 'react'
import DashboardPage from '../../modules/admintbk/DashboardPage'
import ProductPage from '../../modules/cassier/ProductPage'
import AllProdukPage from '../../modules/admintbk/AllProdukPage'
import DaftarPembelianPage from '../../modules/admintbk/DaftarPembelianPage'

const DaftarPembelian = () => {
  return <DaftarPembelianPage></DaftarPembelianPage>
}

export default DaftarPembelian
