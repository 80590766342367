import React, { ChangeEvent, useEffect, useState } from 'react'
import usePageTitle from '../../functions/global/usePageTitle'
import { Button, Modal } from 'react-bootstrap'
import { KTCard, KTSVG } from '../../../_metronic/helpers'
import {
  addCategory,
  addNewProduct,
  deleteProduct,
  fetchCategories,
  showProducts,
  showSupplier,
  showUnit,
  showUnitData,
  showUnitData2,
  updateProduct,
} from '../../functions/global/api'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { convertDate, convertIDR, formatCurrency, getDaysRemaining } from '../../functions/global'
import * as XLSX from 'xlsx'
import { useNavigate } from 'react-router-dom'
import { Empty, message, Pagination } from 'antd'

const API_URL = process.env.REACT_APP_API_URL

interface Category {
  id: number
  category_name: string
}
interface Supplier {
  id: number
  name: string
}

type PriceListItem = {
  id: number
  unit_name: string
  unit_value: number
  pivot: {
    product_id: number
    unit_id: number
    price: number
    minimum: number
    satuan: string
  }
}

interface Code {
  code: string
  note: string
}

interface AddProduct {
  category_id: string
  product_name: string
  stock: number
  discount: number
  supplier_id: string
  hpp_price: number
  faktur_number: string
  expired_date: string
  expired_notif_date: string
  expired_notif_days: number

  units: {
    type: string
    name: string
    conversion: number
    price: number
    multi_qty_1: number
    multi_price_1: number
    multi_qty_2: number
    multi_price_2: number
    multi_qty_3: number
    multi_price_3: number
    multi_qty_4: number
    multi_price_4: number
  }[]
  codes: {
    code: string
    note: string
  }[]
}

interface UnitData {
  id: number;
  type: string;
  name: string;
}

const ProductPage = () => {
  usePageTitle('Semua Produk')

  const navigate = useNavigate()
  const [productData, setProductData] = useState<any[]>([])
  const [category, setCategory] = useState<any[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [supplier, setSupplier] = useState<Supplier[]>([])
  const [unitData, setUnitData] = useState<UnitData[]>([])
  const [searchQuery, setSearchQuery] = useState<string>(''); // State untuk menyimpan query pencarian
  const [unit, setUnit] = useState<any[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false)
  const [productToEdit, setProductToEdit] = useState<number | null>(null)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [productToDelete, setProductToDelete] = useState<number | null>(null)
  const [showProdukConfirmation, setShowProdukConfirmation] = useState(false)
  const [produkToProduk, setProdukToProduk] = useState<number | null>(null)
  const [newProduct, setNewProduct] = useState<AddProduct>({
    category_id: '',
    product_name: '',
    stock: 0,
    discount: 0,
    supplier_id: '',
    hpp_price: 0,
    faktur_number: '',
    expired_date: '',
    expired_notif_date: '',
    expired_notif_days: 0,
    units: [],
    codes: [],
  })



  useEffect(() => {
    if (productToEdit !== null) {
      const productToEditData = productData.find((product) => product.id === productToEdit)

      console.log('Product to edit:', productToEditData)

      if (productToEditData) {
        const stockOpname = productToEditData.stock_opname
        console.log('Stock opname:', stockOpname)

        console.log('Product codes:')
        productToEditData.codes.forEach((code: any, index: number) => {
          console.log(`Code ${index + 1}:`, code)
        })

        console.log('Product units:')
        productToEditData.units.forEach((unit: any, index: number) => {
          console.log(`Unit ${index + 1}:`, unit)
        })

        setNewProduct({
          ...newProduct,
          category_id: productToEditData.category_id,
          product_name: productToEditData.product_name,
          stock: productToEditData.stock,
          discount: productToEditData.discount,
          supplier_id: stockOpname?.supplier_id || '',
          hpp_price: stockOpname?.hpp_price || [0],
          faktur_number: stockOpname?.faktur_number || 0,
          expired_date: stockOpname?.expired_date ? stockOpname.expired_date.substring(0, 10) : '',
          expired_notif_date: stockOpname?.expired_notif_date
            ? stockOpname.expired_notif_date.substring(0, 10)
            : '',
          expired_notif_days: stockOpname?.expired_notif_days || 0,
          codes: productToEditData.codes.map((code: { id: any; code: any; note: any }) => ({
            id: code.id,
            code: code.code,
            note: code.note,
          })),
          units: productToEditData.units.map(
            (unit: {
              id: any
              name: any
              type: string
              conversion: any
              price: any
              multi_qty_1: any
              multi_price_1: any
              multi_qty_2: any
              multi_price_2: any
              multi_qty_3: any
              multi_price_3: any
              multi_qty_4: any
              multi_price_4: any
            }) => ({
              id: unit.id,
              name: unit.name,
              type: unit.type,
              conversion: unit.conversion,
              price: unit.price,
              multi_qty_1: unit.multi_qty_1,
              multi_price_1: unit.multi_price_1,
              multi_qty_2: unit.multi_qty_2,
              multi_price_2: unit.multi_price_2,
              multi_qty_3: unit.multi_qty_3,
              multi_price_3: unit.multi_price_3,
              multi_qty_4: unit.multi_qty_4,
              multi_price_4: unit.multi_price_4,
            })
          ),
        })
      }
    }
  }, [productToEdit, productData])

  // Menutup dropdown ketika klik di luar area dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest('.dropdown')) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const [newCategory, setNewCategory] = useState({
    category_name: '',
    code: '',
  })
  const [arrangeBy, setArrangeBy] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const handleSearch = (eventOrPageNumber: React.MouseEvent<HTMLButtonElement> | number) => {
    if (typeof eventOrPageNumber === 'number') {
      const pageNumber = eventOrPageNumber

      const productCode = newProduct.codes.length > 0 ? newProduct.codes[0].code : ''

      const searchParams = {
        product_name: newProduct.product_name,
        category_id: newProduct.category_id,
        product_code: productCode,
        arrange_by: arrangeBy,
        sort_by: sortBy,
      }

      axios
        .get(
          `${API_URL}/product?page=&product_name=&category_id=&product_code=&arrange_by=&sort_by=`,
          {
            params: { ...searchParams, page: pageNumber },
          }
        )
        .then((response) => {
          console.log('pieki', response.data.data.last_page);

          setProductData(response.data.data.data)
          setCurrentPage(pageNumber)
          setTotalPages(response.data.data.last_page)
        })
        .catch((error) => {
          console.error('Error searching products:', error)
        })
    } else {
    }
  }

  const exportToExcel = () => {
    try {
      const productCode = newProduct.codes.length > 0 ? newProduct.codes[0].code : ''
      const searchParams = {
        product_name: newProduct.product_name,
        category_id: newProduct.category_id,
        product_code: productCode,

        arrange_by: arrangeBy,
        sort_by: sortBy,
      }

      axios
        .get(
          `${API_URL}/product?page=&product_name=&category_id=&product_code=&arrange_by=&sort_by=`,
          {
            params: { ...searchParams, page: 1 },
          }
        )
        .then((response) => {
          const dataToExport = (response.data.data.data as any[]).map((product: any) => {
            const stockOpname = product.stock_opname[0]
            const hargaJual = product.price_lists.map(
              (priceList: PriceListItem, index: number) => ({
                [`Harga Jual ${index + 1}`]: convertIDR(priceList.pivot.price || 0),
                [`Minimum ${index + 1}`]: priceList.pivot.minimum || 0,
                [`Unit ${index + 1}`]: priceList.unit_name || 'N/A',
              })
            )

            return {
              'Nama Produk': product.product_name,
              Kategori: product.category ? product.category.category_name : 'N/A',
              Kode: product.product_code,
              HPP: convertIDR(product.stock_opname?.hpp_price),
              Stok: product.stock,
              'No Faktur': product.stock_opname?.faktur_number,
              'Tanggal Kadaluarsa': convertDate(product.stock_opname?.expired_date),
              'Notif Tanggal Kadaluarsa': convertDate(product.stock_opname?.expired_notif_date),
              ...Object.assign({}, ...hargaJual),
            }
          })

          const ws = XLSX.utils.json_to_sheet(dataToExport)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Products')
          XLSX.writeFile(wb, 'products.xlsx')
        })
        .catch((error) => {
          console.error('Error exporting to Excel:', error)
        })
    } catch (error) {
      console.error('Error exporting to Excel:', error)
    }
  }

  // Filter supplier berdasarkan search query
  const filteredSuppliers = supplier.filter((supplier) =>
    supplier.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Fungsi untuk menangani perubahan pada input pencarian
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Fungsi untuk menangani perubahan pada dropdown
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setNewProduct({ ...newProduct, supplier_id: e.target.value });
  };

  console.log(totalPages, 'code')

  const showData = () => {
    showProducts().then((data) => {
      console.log('datarespoonse', data);
      setProductData(data)
    })
  }

  useEffect(() => {
    fetchCategories().then((dataKategori) => {
      setCategories(dataKategori)
    })

    showSupplier().then((data) => {
      console.log('supplier', data);

      setSupplier(data)
    })

    showUnitData2().then((data) => {
      setUnitData(data)
    })
    handleSearch(1)
    // showData()
  }, [])

  const handleDeleteProduct = async (id_product: number) => {
    try {
      const response = await deleteProduct(id_product)

      if (response.status === 200) {
        const updatedProductData = productData.filter((item) => item.id !== id_product)
        setProductData(updatedProductData)
        message.success('Produk telah dihapus')
      } else {
        console.error('Gagal menghapus produk, respons:', response)
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat menghapus produk:', error)
    }
  }

  const addKategori = async () => {
    try {
      const response = await addCategory({
        category_name: newCategory.category_name,
        code: newCategory.code,
      })
      console.log('Response:', response)
      if (response.status === 'success') {
        console.log('Product created successfully')
        showData()
        setShowNewCategoryInput(false)
        setCategories([...categories, response.data])
        setCategory([...category, response.data])
        message.success('Kategori telah ditambah')

        setNewCategory({
          category_name: '',
          code: '',
        })
      } else {
        console.error('Gagal menambahkan produk, respons:', response)
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat menambahkan produk:', error)
    }
  }

  const addProduct = async () => {
    if (!newProduct.expired_notif_date) {
      message.warning('Tanggal notifikasi kadaluarsa tidak boleh kosong.')
      return
    }

    try {
      const requestData = {
        category_id: newProduct.category_id,
        product_name: newProduct.product_name,
        stock: newProduct.stock,
        discount: newProduct.discount,
        supplier_id: newProduct.supplier_id,
        hpp_price: newProduct.hpp_price,
        faktur_number: newProduct.faktur_number,
        expired_date: newProduct.expired_date,
        expired_notif_date: newProduct.expired_notif_date,
        units: units.map((unit, index) => ({
          name: unit.name,
          type: unit.type,
          conversion: unit.conversion,
          price: unit.price,
          multi_qty_1: unit.multi_qty_1,
          multi_price_1: unit.multi_price_1,
          multi_qty_2: unit.multi_qty_2,
          multi_price_2: unit.multi_price_2,
          multi_qty_3: unit.multi_qty_3,
          multi_price_3: unit.multi_price_3,
          multi_qty_4: unit.multi_qty_4,
          multi_price_4: unit.multi_price_4,
        })),
        codes: codes.map((code) => ({
          code: code.code,
          note: code.note,
        })),
      }

      console.log('Request Data:', requestData)

      const response = await addNewProduct(requestData)

      console.log('Response:', response)
      if (response.status === 'success') {
        console.log('Product created successfully')
        showData()
        setProductData([...productData, response.data])
        message.success('Data telah dibuat')
        setShowAddModal(false)
        setNewProduct({
          category_id: '',
          product_name: '',
          stock: 0,
          discount: 0,
          supplier_id: '',
          hpp_price: 0,
          faktur_number: '',
          expired_date: '',
          expired_notif_date: '',
          expired_notif_days: 0,
          units: [],
          codes: [],
        })
      } else {
        console.error('Gagal menambahkan produk, respons:', response)
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat menambahkan produk:', error)
    }
  }

  const simpanPerubahanProduk = () => {
    if (
      !newProduct.category_id ||
      !newProduct.product_name ||
      newProduct.stock <= 0 ||
      !newProduct.supplier_id
    ) {
      message.error('Semua kolom harus terisi semua.')
      return
    }

    if (productToEdit !== null) {
      console.log('Product Data:', productToEdit, newProduct)

      const updatedUnits = newProduct.units.map((unit, index) => ({
        type: unit.type,
        name: unit.name,
        conversion: unit.conversion,
        price: unit.price,
        multi_qty_1: unit.multi_qty_1,
        multi_price_1: unit.multi_price_1,
        multi_qty_2: unit.multi_qty_2,
        multi_price_2: unit.multi_price_2,
        multi_qty_3: unit.multi_qty_3,
        multi_price_3: unit.multi_price_3,
        multi_qty_4: unit.multi_qty_4,
        multi_price_4: unit.multi_price_4,
      }))

      const updatedProduct = {
        ...newProduct,
        units: updatedUnits,
      }

      updateProduct(
        productToEdit,
        updatedProduct.category_id,
        updatedProduct.product_name,
        updatedProduct.stock,
        updatedProduct.discount,
        updatedProduct.supplier_id,
        updatedProduct.hpp_price,
        updatedProduct.faktur_number,
        updatedProduct.expired_date,
        updatedProduct.expired_notif_date,
        updatedProduct.units,
        updatedProduct.codes
      )
        .then((response) => {
          console.log('Produk diperbarui:', response)
          showData()
          message.success('Produk telah diubah')
        })
        .catch((error) => {
          console.error('Kesalahan mengedit produk:', error)
        })

      setShowEditConfirmation(false)
    }
  }
  const handleSelectSupplier = (id: string,itme:any) => {
    setNewProduct({ ...newProduct, supplier_id: id });
    
    setIsDropdownOpen(false); // Tutup dropdown setelah memilih
  };
  const openProdukConfirmation = (id_product: number) => {
    console.log('Data produk:', productData)
    setProdukToProduk(id_product)
    setShowProdukConfirmation(true)
  }

  const openEditConfirmation = (productId: number) => {
    setProductToEdit(productId)
    setShowEditConfirmation(true)
  }

  const openDeleteConfirmation = (productId: number) => {
    setProductToDelete(productId)
    setShowDeleteConfirmation(true)
  }
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(amount)
  }

  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false)

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory = e.target.value
    if (selectedCategory === '') {
      setShowNewCategoryInput(false)
    } else if (selectedCategory === 'new') {
      setShowNewCategoryInput(true)
    } else {
      setNewProduct({ ...newProduct, category_id: selectedCategory })
      setShowNewCategoryInput(false)
    }
  }

  const [units, setUnits] = useState([
    {
      type: '',
      name: '',
      conversion: 0,
      price: 0,
      multi_qty_1: 0,
      multi_price_1: 0,
      multi_qty_2: 0,
      multi_price_2: 0,
      multi_qty_3: 0,
      multi_price_3: 0,
      multi_qty_4: 0,
      multi_price_4: 0,
    },
  ])

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const newUnits = [...units]
    newUnits[index] = { ...newUnits[index], [name]: value }
    setUnits(newUnits)
  }
  const handleChangeSelectUnits = (index: number, event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const [type, name] = value.split('|'); // Split the combined value into type and name
    const newUnits = [...units];
    newUnits[index] = { ...newUnits[index], type, name }; // Update the unit with extracted type and name
    setUnits(newUnits);
  };


  const addUnit = () => {
    setUnits([
      ...units,
      {
        type: '',
        name: '',
        conversion: 0,
        price: 0,
        multi_qty_1: 0,
        multi_price_1: 0,
        multi_qty_2: 0,
        multi_price_2: 0,
        multi_qty_3: 0,
        multi_price_3: 0,
        multi_qty_4: 0,
        multi_price_4: 0,
      },
    ])
  }

  const removeUnit = (index: number) => {
    const newUnits = [...units]
    newUnits.splice(index, 1)
    setUnits(newUnits)
  }

  const initialCodeState: Code = { code: '', note: '' }
  const [codes, setCodes] = useState<Code[]>([{ ...initialCodeState }])

  const handleChangeCode = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const newCodes = [...codes]
    newCodes[index] = { ...newCodes[index], [name]: value }
    setCodes(newCodes)
  }

  const addCode = () => {
    setCodes([...codes, { ...initialCodeState }])
  }

  const removeCode = (index: number) => {
    const newCodes = [...codes]
    newCodes.splice(index, 1)
    setCodes(newCodes)
  }

  const addCodeUpdate = () => {
    const updatedCodes = [...newProduct.codes, { code: '', note: '' }]
    setNewProduct({ ...newProduct, codes: updatedCodes })
  }

  const removeCodeUpdate = (index: number) => {
    const updatedCodes = [...newProduct.codes]
    updatedCodes.splice(index, 1)
    setNewProduct({ ...newProduct, codes: updatedCodes })
  }

  const removeUnitUpdate = (index: number) => {
    setNewProduct((prevProduct) => {
      const updatedUnits = [...prevProduct.units]
      updatedUnits.splice(index, 1)
      return { ...prevProduct, units: updatedUnits }
    })
  }

  const handleChangeUpdate = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewProduct((prevProduct) => {
      const updatedUnits = [...prevProduct.units];
      updatedUnits[index] = { ...updatedUnits[index], [name]: value };
      return { ...prevProduct, units: updatedUnits };
    });
  };

  const handleChangeUpdateSelectUnits = (index: number, e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const [type, name] = value.split('|');

    setNewProduct((prevProduct) => {
      const updatedUnits = [...prevProduct.units];
      updatedUnits[index] = {
        ...updatedUnits[index],
        type,
        name,
      };
      return { ...prevProduct, units: updatedUnits };
    });
  };



  const addUnitUpdate = () => {
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      units: [
        ...prevProduct.units,
        {
          name: '',
          type: '',
          conversion: 0,
          price: 0,
          multi_qty_1: 0,
          multi_price_1: 0,
          multi_qty_2: 0,
          multi_price_2: 0,
          multi_qty_3: 0,
          multi_price_3: 0,
          multi_qty_4: 0,
          multi_price_4: 0,
        },
      ],
    }))
  }

  const openModal = () => {
    setShowAddModal(true)
    setNewProduct({
      category_id: '',
      product_name: '',
      stock: 0,
      discount: 0,
      supplier_id: '',
      hpp_price: 0,
      faktur_number: '',
      expired_date: '',
      expired_notif_date: '',
      expired_notif_days: 0,
      units: [],
      codes: [],
    })
  }

  const closeModal = () => {
    setShowAddModal(false)
    setNewProduct({
      category_id: '',
      product_name: '',
      stock: 0,
      discount: 0,
      supplier_id: '',
      hpp_price: 0,
      faktur_number: '',
      expired_date: '',
      expired_notif_date: '',
      expired_notif_days: 0,
      units: [],
      codes: [],
    })
  }
  useEffect(() => {
    if (newProduct.expired_notif_date) {
      const currentDate = new Date()
      const notifDate = new Date(newProduct.expired_notif_date)
      const diffTime = Math.abs(notifDate.getTime() - currentDate.getTime())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      setNewProduct((prevProduct) => ({
        ...prevProduct,
        expired_notif_days: diffDays,
      }))
    }
  }, [newProduct.expired_notif_date])


  return (
    <>
      <KTCard>
        <div className='card card-flush pt-3 mb-6 mb-lg-6'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Filter</h3>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row fw-bold'>
              <div className='col-3'>
                <label className='mb-2'>Nama / Kode Produk</label>
                <input
                  placeholder='Masukkan nama / kode produk'
                  className='form-control'
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch(1)
                    }
                  }}
                  type='text'
                  name='search'
                  onChange={(e) => setNewProduct({ ...newProduct, product_name: e.target.value })}
                />
              </div>
              <div className='col-3'>
                <label className='mb-2'>Kategori</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={newProduct.category_id}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      handleSearch(1)
                    }
                  }}
                  onChange={(e) => setNewProduct({ ...newProduct, category_id: e.target.value })}
                >
                  <option value=''>Pilih Kategori</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-3'>
                <label className='mb-2'>Urutkan Berdasarkan</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={arrangeBy}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      handleSearch(1)
                    }
                  }}
                  onChange={(e) => setArrangeBy(e.target.value)}
                  required
                >
                  <option value='category_id'>Kategori</option>
                  <option value='product_code'>Kode Produk</option>
                  <option value='product_name'>Nama Produk</option>
                </select>
              </div>
              <div className='col-3'>
                <label className='mb-2'>Urutan</label>
                <select
                  className='form-select'
                  name='modeProcess'
                  value={sortBy}
                  onChange={(e) => {
                    if (arrangeBy) {
                      setSortBy(e.target.value)
                    } else {
                      message.error(
                        'Tolong isikan "Urutan" dahulu sebelum memilih "Urutkan Berdasarkan".'
                      )
                    }
                  }}
                  required
                >
                  <option value='asc'>A-Z</option>
                  <option value='desc'>Z-A</option>
                </select>
              </div>
              <div className='col-3 mt-6'>
                <Button className='btn btn-sm' onClick={() => handleSearch(1)}>
                  Cari
                </Button>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
      <KTCard className='mt-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Semua Produk</h3>
          </div>
        </div>
        <div className='separator separator'></div>
        {/* tabel */}
        <div className='card-body'>
          <div className='table-responsive'>
            <div className='d-flex align-items-center position-relative mb-6'>
              <Button className='btn btn-sm' onClick={openModal}>
                Tambah Produk
              </Button>
            </div>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center'>
                  <th className='ps-4 rounded-start'>No</th>
                  <th>Nama Produk</th>
                  <th>Kategori</th>
                  <th>Kode Produk</th>
                  <th>Stok</th>
                  <th className='pe-4 rounded-end'>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(productData) ? (
                  productData.map((item, index) => (
                    <tr key={index} className='text-center'>
                      <td align='center'>{(currentPage - 1) * 10 + index + 1}</td>
                      <td>
                        <button type='button' className='btn btn-text'>
                          <a
                            onClick={() => openProdukConfirmation(item.id)}
                            className='text-dark fw-bold text-hover-primary fs-6'
                          >
                            {item.product_name}
                          </a>
                        </button>
                      </td>
                      <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {item.category ? item.category.category_name : 'N/A'}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {Array.isArray(item.codes)
                            ? item.codes.map((codeItem: { code: any }) => codeItem.code).join(', ')
                            : 'No codes available'}
                        </div>
                      </td>

                      <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>{item.stock}</div>
                      </td>
                      <td>
                        <button
                          className='btn btn-info btn-sm'
                          onClick={() => openEditConfirmation(item.id)}
                        >
                          Edit
                        </button>
                        <span> </span>
                        <button
                          className='btn btn-danger btn-sm'
                          onClick={() => openDeleteConfirmation(item.id)}
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className='text-center text-muted'>
                      <Empty
                        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                        imageStyle={{ height: 60 }}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* pagination */}
          <div className='row mb-6 mt-6'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <Pagination
                current={currentPage}
                total={totalPages * 10}
                onChange={(page) => handleSearch(page)}
                showSizeChanger={false}
                pageSize={10}
              />
            </div>
          </div>

          {/* Modal Tambah Produk */}
          {showAddModal && (
            <div
              className='modal fade show d-block'
              id='kt_modal_add_user'
              tabIndex={-1}
              aria-modal='true'
            >
              <div className='modal-dialog modal-fullscreen'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='exampleModalLabel'>
                      Tambah Produk
                    </h1>
                    <button
                      type='button'
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      aria-label='Close'
                      onClick={closeModal}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </button>
                  </div>
                  <div className='modal-body'>
                    <form>
                      <div className='mb-3'>
                        <label className='col-form-label'>Nama Produk:</label>
                        <input
                          type='text'
                          placeholder='Masukkan nama produk'
                          className='form-control'
                          id='product-name'
                          value={newProduct.product_name}
                          onChange={(e) =>
                            setNewProduct({ ...newProduct, product_name: e.target.value })
                          }
                        />
                      </div>
                      <div className='mb-3'>
                        <label className='col-form-label'>Kategori:</label>
                        <select
                          className='form-select'
                          name='modeProcess'
                          value={newProduct.category_id}
                          onChange={handleCategoryChange}
                        >
                          <option value=''>Pilih Kategori</option>
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.category_name}
                            </option>
                          ))}
                          <option value='new'>Lainnya</option>
                        </select>
                        {showNewCategoryInput && (
                          <div>
                            <label className='col-form-label'>Kategori Baru:</label>
                            <input
                              type='text'
                              placeholder='Isikan nama kategori baru'
                              className='form-control mt-2'
                              value={newCategory.category_name}
                              onChange={(e) =>
                                setNewCategory({ ...newCategory, category_name: e.target.value })
                              }
                            />
                            <input
                              type='text'
                              placeholder='Isikan kode kategori baru'
                              className='form-control mt-2'
                              value={newCategory.code}
                              onChange={(e) =>
                                setNewCategory({ ...newCategory, code: e.target.value })
                              }
                            />
                            <button
                              type='button'
                              className='btn btn-primary mt-2'
                              onClick={addKategori}
                            >
                              Tambah
                            </button>
                          </div>
                        )}
                      </div>

                      <div className='mb-3'>
                        {codes.map((code, index) => (
                          <div className='d-flex flex-row mb-4 align-items-center' key={index}>
                            <div className='col-md-4 me-4'>
                              <label className='col-form-label'>Kode Produk:</label>
                              <input
                                type='text'
                                className='form-control'
                                name='code'
                                value={code.code}
                                onChange={(e) => handleChangeCode(index, e)}
                                placeholder='Code'
                              />
                            </div>
                            <div className='col-md-4 me-4'>
                              <label className='col-form-label'>Keterangan:</label>
                              <input
                                type='text'
                                className='form-control'
                                name='note'
                                value={code.note}
                                onChange={(e) => handleChangeCode(index, e)}
                                placeholder='Note'
                              />
                            </div>
                            {codes.length > 1 && (
                              <div className='align-self-md-center mt-12'>
                                <button
                                  type='button'
                                  data-repeater-delete=''
                                  className='btn btn-sm btn-icon btn-light-danger'
                                  onClick={() => removeCode(index)}
                                >
                                  <i className='bi-duotone bi-x fs-1'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                        <button type='button' className='btn btn-sm btn-primary' onClick={addCode}>
                          Tambah Kode Produk
                        </button>
                      </div>

                      <div className='mb-3'>
                        <label className='col-form-label'>HPP:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={newProduct.hpp_price}
                          onChange={(e) =>
                            setNewProduct({
                              ...newProduct,
                              hpp_price: parseFloat(e.target.value) || 0,
                            })
                          }
                        />
                      </div>

                      <div className='mb-4' style={{ overflowX: 'auto' }}>
                        <label className='col-form-label'>Harga Unit:</label>
                        {units.map((unit, index) => (
                          <div className='d-flex flex-row mb-4' key={index}>
                            <div className='col-md-2 me-4 text-nowrap'>
                              <label className='col-form-label'>Unit Satuan:</label>
                              <select
                                className='form-select'
                                name='typeName'
                                value={`${unit.type}|${unit.name}`}
                                onChange={(e) => handleChangeSelectUnits(index, e)}
                              >
                                <option value=''>Pilih Unit</option>
                                {unitData.map((unitDataItem) => (
                                  <option
                                    key={unitDataItem.id}
                                    value={`${unitDataItem.type}|${unitDataItem.name}`}
                                  >
                                    {`${unitDataItem.type} - ${unitDataItem.name}`}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Konversi:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`conversion-${index}`}
                                name='conversion'
                                value={unit.conversion}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Conversion'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Harga:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`price-${index}`}
                                name='price'
                                value={unit.price}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Price'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Jumlah Borongan 1:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`multi_qty_1-${index}`}
                                name='multi_qty_1'
                                value={unit.multi_qty_1}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Multi Qty 1'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Harga Borongan 1:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`multi_price_1-${index}`}
                                name='multi_price_1'
                                value={unit.multi_price_1}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Multi Price 1'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Jumlah Borongan 2:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`multi_qty_2-${index}`}
                                name='multi_qty_2'
                                value={unit.multi_qty_2}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Multi Qty 2'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Harga Borongan 2:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`multi_price_2-${index}`}
                                name='multi_price_2'
                                value={unit.multi_price_2}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Multi Price 2'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Jumlah Borongan 3:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`multi_qty_3-${index}`}
                                name='multi_qty_3'
                                value={unit.multi_qty_3}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Multi Qty 3'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Harga Borongan 3:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`multi_price_3-${index}`}
                                name='multi_price_3'
                                value={unit.multi_price_3}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Multi Price 3'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Jumlah Borongan 4:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`multi_qty_4-${index}`}
                                name='multi_qty_4'
                                value={unit.multi_qty_4}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Multi Qty 4'
                              />
                            </div>

                            <div className='col-md-1 me-4 text-nowrap'>
                              <label className='col-form-label'>Harga Borongan 4:</label>
                              <input
                                type='number'
                                className='form-control'
                                id={`multi_price_4-${index}`}
                                name='multi_price_4'
                                value={unit.multi_price_4}
                                onChange={(e) => handleChange(index, e)}
                                placeholder='Multi Price 4'
                              />
                            </div>

                            {units.length > 1 && (
                              <div className='align-self-md-center mt-12'>
                                <button
                                  type='button'
                                  data-repeater-delete=''
                                  className='btn btn-sm btn-icon btn-light-danger'
                                  onClick={() => removeUnit(index)}
                                >
                                  <i className='bi-duotone bi-x fs-1'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                        <button
                          type='button'
                          className='btn btn-sm btn-primary mb-6'
                          onClick={addUnit}
                        >
                          Tambah Harga Jual
                        </button>
                      </div>

                      <div className='mb-3'>
                        <label className='col-form-label'>Stok:</label>
                        <input
                          type='quantity'
                          placeholder='0'
                          className='form-control'
                          min={0}
                          value={newProduct.stock || '0'}
                          onChange={(e) =>
                            setNewProduct({ ...newProduct, stock: parseInt(e.target.value) })
                          }
                        />
                      </div>

                      <div className='mb-3'>
                        <label className='col-form-label'>Diskon:</label>
                        <input
                          type='text'
                          placeholder='Isikan 0 jika tidak ada diskon'
                          className='form-control '
                          id='discount'
                          value={newProduct.discount}
                          onChange={(e) =>
                            setNewProduct({
                              ...newProduct,
                              discount: parseInt(e.target.value.replace(/\D/g, ''), 10) || 0,
                            })
                          }
                        />
                      </div>

                      <div className='mb-3'>
                        <label className='col-form-label'>Nama Supplier:</label>
                        <div className='dropdown'>
                          {/* Tombol untuk membuka dropdown */}
                          <div
                            className='form-control custom-dropdown-div'
                            style={{ paddingLeft: '5px', cursor: 'pointer' }}
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          >
                            {newProduct.supplier_id
                              ? supplier.find((s) => s.id.toString() === newProduct.supplier_id)?.name
                              : 'Pilih Supplier'}
                          </div>


                          {/* Dropdown custom */}
                          {isDropdownOpen && (
                            <div className='dropdown-menu show' style={{ display: 'block', width: '100%' }}>
                              {/* Input untuk pencarian supplier */}
                              <input
                                type='text'
                                className='form-control mb-2'
                                placeholder='Cari Supplier...'
                                value={searchQuery}
                                onChange={handleSearchChange}
                                autoFocus
                              />

                              {/* List opsi supplier */}
                              <ul className='list-group'>
                                {filteredSuppliers.length > 0 ? (
                                  filteredSuppliers.map((supplier) => (
                                    <li
                                      key={supplier.id}
                                      className='list-group-item list-group-item-action'
                                      onClick={() => handleSelectSupplier(supplier.id.toString(),supplier)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {supplier.name}
                                    </li>
                                  ))
                                ) : (
                                  <li className='list-group-item'>Supplier tidak ditemukan</li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>



                      <div className='mb-3'>
                        <label className='col-form-label'>No Faktur:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={newProduct.faktur_number || ''}
                          onChange={(e) =>
                            setNewProduct({
                              ...newProduct,
                              faktur_number: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className='mb-3'>
                        <label className='col-form-label'>Tanggal Kadaluarsa:</label>
                        <input
                          type='date'
                          className='form-control'
                          id='product-name'
                          value={newProduct.expired_date}
                          onChange={(e) =>
                            setNewProduct({ ...newProduct, expired_date: e.target.value })
                          }
                        />
                      </div>
                      <div className='mb-3'>
                        <label className='col-form-label'>Notif Tanggal Kadaluarsa:</label>
                        <select
                          className='form-select'
                          value={newProduct.expired_notif_days}
                          onChange={(e) => {
                            const selectedDays = parseInt(e.target.value)
                            const currentDate = new Date()
                            const newExpiredNotifDate = new Date(
                              currentDate.setDate(currentDate.getDate() + selectedDays)
                            )
                              .toISOString()
                              .split('T')[0]
                            setNewProduct({
                              ...newProduct,
                              expired_notif_days: selectedDays,
                              expired_notif_date: newExpiredNotifDate,
                            })
                          }}
                        >
                          {Array.from({ length: 180 }, (_, index) => (
                            <option key={index + 1} value={index + 1}>
                              {index + 1} hari
                            </option>
                          ))}
                        </select>
                      </div>
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' onClick={closeModal}>
                      Close
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary'
                      data-bs-dismiss='modal'
                      onClick={addProduct}
                    >
                      Tambah Produk
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* End Alert Tambah Produk */}
        </div>

        {/* Edit */}
        {showEditConfirmation && productToEdit !== null && (
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-fullscreen'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='modal-title fs-5'>Edit Produk</h2>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    aria-label='Close'
                    onClick={() => setShowEditConfirmation(false)}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </button>
                </div>
                <div className='modal-body'>
                  <form>
                    <div className='mb-3'>
                      <label className='col-form-label'>Nama Produk:</label>
                      <input
                        type='text'
                        className='form-control'
                        value={newProduct.product_name}
                        onChange={(event) =>
                          setNewProduct({ ...newProduct, product_name: event.target.value })
                        }
                      />
                    </div>
                    <div className='mb-3'>
                      <label className='col-form-label'>Kategori:</label>
                      <select
                        className='form-select'
                        name='category_id'
                        value={newProduct.category_id}
                        onChange={(e) =>
                          setNewProduct({ ...newProduct, category_id: e.target.value })
                        }
                      >
                        <option value=''>Pilih Kategori</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='mb-3'>
                      {newProduct.codes.map((code, index) => (
                        <div className='d-flex flex-row mb-4 align-items-center' key={index}>
                          <div className='col-md-4 me-4'>
                            <label className='col-form-label'>Kode Produk:</label>
                            <input
                              type='text'
                              className='form-control'
                              name='code'
                              value={newProduct.codes[index].code}
                              onChange={(e) => {
                                const updatedCodes = [...newProduct.codes]
                                updatedCodes[index] = { ...updatedCodes[index], code: e.target.value }
                                setNewProduct({ ...newProduct, codes: updatedCodes })
                              }}
                              placeholder='Code'
                            />
                          </div>

                          <div className='col-md-4 me-4'>
                            <label className='col-form-label'>Keterangan:</label>
                            <input
                              type='text'
                              className='form-control'
                              name='note'
                              value={newProduct.codes[index].note}
                              onChange={(e) => {
                                const updatedCodes = [...newProduct.codes]
                                updatedCodes[index] = { ...updatedCodes[index], note: e.target.value }
                                setNewProduct({ ...newProduct, codes: updatedCodes })
                              }}
                              placeholder='Note'
                            />
                          </div>

                          {newProduct.codes.length > 1 && (
                            <div className='align-self-md-center mt-12'>
                              <button
                                type='button'
                                data-repeater-delete=''
                                className='btn btn-sm btn-icon btn-light-danger'
                                onClick={() => removeCodeUpdate(index)}
                              >
                                <i className='bi-duotone bi-x fs-1'>
                                  <span className='path1' />
                                  <span className='path2' />
                                </i>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        onClick={addCodeUpdate}
                      >
                        Tambah Kode Produk
                      </button>
                    </div>

                    <div className='mb-3'>
                      <label className='col-form-label'>HPP:</label>
                      <input
                        type='text'
                        className='form-control'
                        value={newProduct.hpp_price}
                        onChange={(event) => {
                          const value = event.target.value
                          const parsedValue = parseFloat(value)
                          setNewProduct({
                            ...newProduct,
                            hpp_price: isNaN(parsedValue) ? 0 : parsedValue,
                          })
                        }}
                      />
                    </div>

                    <div className='mb-4' style={{ overflowX: 'auto' }}>
                      <label className='col-form-label'>Harga Unit:</label>
                      {newProduct.units.map((unit, index) => (
                        <div className='d-flex flex-row mb-4' key={index}>

                          <div className='col-md-2 me-4 text-nowrap'>
                            <label className='col-form-label'>Unit Satuan:</label>
                            <select
                              className='form-select'
                              name='typeName'
                              value={`${unit.type}|${unit.name}`}
                              onChange={(e) => handleChangeUpdateSelectUnits(index, e)}
                            >
                              <option value=''>Pilih Unit</option>
                              {unitData.map((unitDataItem) => (
                                <option
                                  key={unitDataItem.id}
                                  value={`${unitDataItem.type}|${unitDataItem.name}`}
                                >
                                  {`${unitDataItem.type} - ${unitDataItem.name}`}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Konversi:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`conversion-${index}`}
                              name='conversion'
                              value={newProduct.units[index].conversion}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Conversion'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Harga:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`price-${index}`}
                              name='price'
                              value={newProduct.units[index].price}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Price'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Jumlah Borongan 1:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`multi_qty_1-${index}`}
                              name='multi_qty_1'
                              value={newProduct.units[index].multi_qty_1}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Multi Qty 1'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Harga Borongan 1:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`multi_price_1-${index}`}
                              name='multi_price_1'
                              value={newProduct.units[index].multi_price_1}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Multi Price 1'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Jumlah Borongan 2:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`multi_qty_2-${index}`}
                              name='multi_qty_2'
                              value={newProduct.units[index].multi_qty_2}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Multi Qty 2'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Harga Borongan 2:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`multi_price_2-${index}`}
                              name='multi_price_2'
                              value={newProduct.units[index].multi_price_2}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Multi Price 2'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Jumlah Borongan 3:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`multi_qty_3-${index}`}
                              name='multi_qty_3'
                              value={newProduct.units[index].multi_qty_3}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Multi Qty 3'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Harga Borongan 3:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`multi_price_3-${index}`}
                              name='multi_price_3'
                              value={newProduct.units[index].multi_price_3}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Multi Price 3'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Jumlah Borongan 4:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`multi_qty_4-${index}`}
                              name='multi_qty_4'
                              value={newProduct.units[index].multi_qty_4}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Multi Qty 4'
                            />
                          </div>

                          <div className='col-md-1 me-4 text-nowrap'>
                            <label className='col-form-label'>Harga Borongan 4:</label>
                            <input
                              type='number'
                              className='form-control'
                              id={`multi_price_4-${index}`}
                              name='multi_price_4'
                              value={newProduct.units[index].multi_price_4}
                              onChange={(e) => handleChangeUpdate(index, e)}
                              placeholder='Multi Price 4'
                            />
                          </div>

                          {newProduct.units.length > 1 && (
                            <div className='align-self-md-center mt-12 text-nowrap'>
                              <button
                                type='button'
                                data-repeater-delete=''
                                className='btn btn-sm btn-icon btn-light-danger'
                                onClick={() => removeUnitUpdate(index)}
                              >
                                <i className='bi-duotone bi-x fs-1'>
                                  <span className='path1' />
                                  <span className='path2' />
                                </i>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      <button
                        type='button'
                        className='btn btn-sm btn-primary mb-6'
                        onClick={addUnitUpdate}
                      >
                        Tambah Harga Jual
                      </button>
                    </div>

                    <div className='mb-3'>
                      <label className='col-form-label'>Stok:</label>
                      <input
                        type='quantity'
                        placeholder='0'
                        className='form-control'
                        min={0}
                        value={newProduct.stock || '0'}
                        onChange={(e) =>
                          setNewProduct({ ...newProduct, stock: parseInt(e.target.value) })
                        }
                      />
                    </div>

                    <div className='mb-3'>
                      <label className='col-form-label'>Diskon:</label>
                      <input
                        type='text'
                        placeholder='Isikan 0 jika tidak ada diskon'
                        className='form-control '
                        id='discount'
                        value={newProduct.discount}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            discount: parseInt(e.target.value.replace(/\D/g, ''), 10) || 0,
                          })
                        }
                      />
                    </div>

                    <div className='mb-3'>
                      <label className='col-form-label'>Nama Supplier:</label>
                      <select
                        className='form-select'
                        name='modeProcess'
                        value={newProduct.supplier_id}
                        onChange={(e) =>
                          setNewProduct({ ...newProduct, supplier_id: e.target.value })
                        }
                      >
                        <option value=''>Pilih Supplier</option>
                        {supplier.map((supplier) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label className='col-form-label'>No Faktur:</label>
                      <input
                        type='text'
                        className='form-control'
                        value={newProduct.faktur_number || ''}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            faktur_number: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className='mb-3'>
                      <label className='col-form-label'>Tanggal Kadaluarsa:</label>
                      <input
                        type='date'
                        className='form-control'
                        id='product-name'
                        value={newProduct.expired_date}
                        onChange={(e) =>
                          setNewProduct({ ...newProduct, expired_date: e.target.value })
                        }
                      />
                    </div>
                    {/* <div className='mb-3'>
            <label className='col-form-label'>Notif Tanggal Kadaluarsa:</label>
            <select
              className='form-select'
              value={newProduct.expired_notif_date}
              onChange={(e) => {
                const selectedDays = parseInt(e.target.value)
                const currentDate = new Date()
                const newExpiredNotifDate = new Date(
                  currentDate.setDate(currentDate.getDate() + selectedDays)
                )
                  .toISOString()
                  .split('T')[0]
                setNewProduct({
                  ...newProduct,
                  expired_notif_days: selectedDays,
                  expired_notif_date: newExpiredNotifDate,
                })
              }}
            >
              {Array.from({length: 180}, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1} hari
                </option>
              ))}
            </select>
          </div> */}
                  </form>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    data-bs-dismiss='modal'
                    onClick={() => setShowEditConfirmation(false)}
                  >
                    Close
                  </button>

                  <button
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    onClick={simpanPerubahanProduk}
                  >
                    Simpan Perubahan
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* End Edit Produk

        {/* Delete */}
        {showDeleteConfirmation && productToDelete !== null && (
          <div>
            <div className='modal-backdrop fade show'></div>
            <div className='modal fade show' style={{ display: 'block' }}>
              <div className='modal-dialog modal-lg'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title' id='deleteConfirmationModalLabel'>
                      Apakah yakin anda ingin menghapus?
                    </h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => setShowDeleteConfirmation(false)}
                    ></button>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      data-bs-dismiss='modal'
                      onClick={() => setShowDeleteConfirmation(false)}
                    >
                      Batal
                    </button>
                    <button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => {
                        handleDeleteProduct(productToDelete)
                        setShowDeleteConfirmation(false)
                      }}
                    >
                      Setuju
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer position='top-right' autoClose={2000} />
      </KTCard>
    </>
  )
}

export default ProductPage
